import { Product } from "../api/interfaces";

type CheckboxRule = {
    // name: string;
    defaultValue: boolean;
    editable: boolean;
};

type TruthyString<T> = Extract<Exclude<T, "">, string>;

export class ProductModel {
    private _prod: Product;

    public static make(prod: Product) {
        const model = new ProductModel(prod);
        return model;
    }

    constructor(prod: Product) {
        this._prod = prod;
    }

    public get id() {
        return this._prod.id;
    }

    public get name() {
        return this._prod.name;
    }

    public get merchandiseCategory() {
        return this._prod.category || null;
    }

    public get alias() {
        return this._prod.alias_for_meme_webform;
    }

    public get sortOrder() {
        return this._prod.sort_order;
    }

    public get customerId(): TruthyString<
        Product["customer_id_for_meme_webform"]
    > | null {
        return this._prod.customer_id_for_meme_webform;
    }

    public get requiredCustomerId() {
        return this.customerId === "必須";
    }
    public get optionalCustomerId() {
        return this.customerId === "任意";
    }
    public get prohibitedCustomerId() {
        return this.customerId === "入力不可";
    }

    public get inputableCustomerId() {
        return this.requiredCustomerId || this.optionalCustomerId;
    }

    public get loginIdType(): TruthyString<
        Product["login_id_type_for_meme_webform"]
    > | null {
        return this._prod.login_id_type_for_meme_webform || null;
    }

    public get loginIdTypeIsEmail() {
        return this.loginIdType === "メールアドレス";
    }

    public get loginIdTypeIsFax() {
        return this.loginIdType === "FAX番号";
    }

    public get loginIdTypeIsOptional() {
        return this.loginIdType === "任意";
    }

    public get loginIdTypeIsProhibited() {
        return this.loginIdType === "入力不可";
    }

    public get inputableLoginIdType() {
        return (
            this.loginIdType === "メールアドレス" ||
            this.loginIdType === "FAX番号" ||
            this.loginIdType === "任意"
        );
    }

    public get loginInfoLastName(): TruthyString<
        Product["login_info_last_name_for_meme_webform"]
    > | null {
        return this._prod.login_info_last_name_for_meme_webform || null;
    }

    public get requiredLoginInfoLastName() {
        return this.loginInfoLastName === "必須";
    }
    public get optionalLoginInfoLastName() {
        return this.loginInfoLastName === "任意";
    }
    public get prohibitedLoginInfoLastName() {
        return this.loginInfoLastName === "入力不可";
    }

    public get inputableLoginInfoLastName() {
        return this.requiredLoginInfoLastName || this.optionalLoginInfoLastName;
    }

    public get loginInfoFirstName(): TruthyString<
        Product["login_info_first_name_for_meme_webform"]
    > | null {
        return this._prod.login_info_first_name_for_meme_webform || null;
    }

    public get requiredLoginInfoFirstName() {
        return this.loginInfoFirstName === "必須";
    }
    public get optionalLoginInfoFirstName() {
        return this.loginInfoFirstName === "任意";
    }
    public get prohibitedLoginInfoFirstName() {
        return this.loginInfoFirstName === "入力不可";
    }

    public get inputableLoginInfoFirstName() {
        return (
            this.requiredLoginInfoFirstName || this.optionalLoginInfoFirstName
        );
    }

    public get loginInfoComment(): TruthyString<
        Product["login_info_comment_for_meme_webform"]
    > | null {
        return this._prod.login_info_comment_for_meme_webform || null;
    }

    public get requiredLoginInfoComment() {
        return this.loginInfoComment === "必須";
    }
    public get optionalLoginInfoComment() {
        return this.loginInfoComment === "任意";
    }
    public get prohibitedLoginInfoComment() {
        return this.loginInfoComment === "入力不可";
    }

    public get inputableLoginInfoComment() {
        return this.requiredLoginInfoComment || this.optionalLoginInfoComment;
    }

    public get loginInfoCommentDefaultValue(): string {
        return this._prod.login_info_comment_default_for_meme_webform || "";
    }

    public get ruleDetailsDl(): CheckboxRule {
        return {
            defaultValue: this._prod.details_dl_default_for_meme_webform,
            editable: this._prod.details_dl_editable_for_meme_webform,
        };
    }

    public get ruleDetailsSplitCode(): CheckboxRule {
        return {
            defaultValue: this._prod.details_split_default_for_meme_webform,
            editable: this._prod.details_split_editable_for_meme_webform,
        };
    }

    public get ruleBreakdownCodeDisplay(): CheckboxRule {
        return {
            defaultValue:
                this._prod.brkdwn_code_display_default_for_meme_webform,
            editable: this._prod.brkdwn_code_display_editable_for_meme_webform,
        };
    }
}
