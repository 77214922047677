<template>
    <footer class="global-footer">
        <p>Copyright © NEXWAY Co.,ltd. All Rights Reserved.</p>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "GlobalFooter",
});
</script>

<style lang="scss" scoped>
.global-footer {
    display: block;
    width: 100%;

    padding-top: 3rem;
    padding-bottom: 1rem;

    text-align: center;
    background-color: #fff;

    p {
        font-weight: 400;
        font-size: 0.75rem;
        color: #333;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
</style>
