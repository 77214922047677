<template>
    <p class="field-error-text" :class="{ bold }"><slot></slot></p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "FieldErrorText",
    props: {
        bold: { type: Boolean, default: false },
    },
});
</script>

<style lang="scss" scoped>
.field-error-text {
    color: #f66;
    font-size: 0.9rem;
    line-height: 1.3rem;
    margin: 0;
    margin-bottom: 0.3rem;

    &.bold {
        font-weight: bold;
    }
}
</style>
