<template>
    <div class="finished-page">
        <GlobalHeader />
        <CenteredLayout>
            <DoAwaiting :for="sendingFormDataPromise">
                <template #resolved>
                    <div class="finished-page-container">
                        <p>
                            ネクスウェイのサービス利用申込をいただきありがとうございました。
                        </p>
                        <p>担当営業より折返し返信させていただきます。</p>
                    </div>
                </template>
                <template #rejected>
                    <div class="rejected-contents">
                        <p style="text-align: center">エラーが発生しました。</p>

                        <div class="error-messages">
                            <ul>
                                <li v-for="(msg, i) in sendFormErrors" :key="i">
                                    <FieldErrorText bold>{{
                                        msg
                                    }}</FieldErrorText>
                                </li>
                            </ul>
                        </div>

                        <div class="buttons-group">
                            <button @click="handleBack">前の画面に戻る</button>
                            <button @click="handleClickReSending">
                                再送する
                            </button>
                            <button @click="handleClickGoToFirstForm">
                                初めからやり直す
                            </button>
                        </div>
                    </div>
                </template>
            </DoAwaiting>
        </CenteredLayout>
        <GlobalFooter />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { ROUTE_PATHS } from "../../../router/paths";
import { usePagination } from "../../../store/pagination";
import { useWebFormStore } from "../../../store/webform";
import FieldErrorText from "../../ui/Atoms/FieldErrorText.vue";
import GlobalFooter from "../../ui/Atoms/GlobalFooter.vue";
import GlobalHeader from "../../ui/Atoms/GlobalHeader.vue";
import CenteredLayout from "../../ui/Templates/CenteredLayout.vue";
import DoAwaiting from "../../utils/DoAwaiting.vue";

export default defineComponent({
    name: "FinishedPage",
    components: {
        GlobalHeader,
        GlobalFooter,
        CenteredLayout,
        DoAwaiting,
        FieldErrorText,
    },
    props: {
        storybook: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const formStore = useWebFormStore();

        const sendingFormDataPromise = computed(
            () => formStore.state._meta.sendingFormData.promise
        );
        const sendFormErrors = computed(
            () => formStore.state._meta.sendingFormData.errors
        );
        const sendFormData = formStore.sendFormData;

        const paginateStore = usePagination();
        const unVisitedFinishedPage = paginateStore.unVisitedFinishedPage;

        return {
            sendingFormDataPromise,
            sendFormErrors,
            sendFormData,
            unVisitedFinishedPage,
        };
    },
    methods: {
        handleClickReSending() {
            if (this.storybook) {
                alert("再送");
            } else {
                this.sendFormData();
            }
        },
        handleBack() {
            this.unVisitedFinishedPage();
            this.$router.back();
        },
        handleClickGoToFirstForm() {
            if (this.storybook) {
                alert("最初の画面に戻る");
            } else {
                this.$router.push({ path: ROUTE_PATHS.FIRST_FORM });
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.finished-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100vh;

    .finished-page-container {
        margin: 5rem 0;
    }

    .rejected-contents {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        .error-messages {
            margin: 1rem 0;
            & > ul {
                color: #f66;
                & > li {
                    margin-bottom: 0.8rem;
                }
            }
        }

        .buttons-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;

            > * + * {
                margin-left: 1rem;
            }
        }
    }
}
</style>
