import { SubmitFormRequest } from "../../../api/interfaces";
import { ProductModel } from "../../../models/product-model";
import { WebFormState } from "../interfaces";

/**
 * @package
 */
export const genFormDataStruct = (
    state: WebFormState,
    productModel: ProductModel,
    actualCompanyName: string
): SubmitFormRequest => ({
    nxw_sales_person_name: state.nxwSalesPerson.name!,
    nxw_sales_person_email: state.nxwSalesPerson.email!,

    registration_type: state.applicationInfo.registrationType as "新規",
    product: productModel.id,

    company_name: actualCompanyName,
    company_name_kana: state.customerInfo.companyNameKana!,
    last_name: state.customerInfo.nameLastName!,
    first_name: state.customerInfo.nameFirstName!,
    last_name_kana: state.customerInfo.nameKanaLastName!,
    first_name_kana: state.customerInfo.nameKanaFirstName!,
    department: state.customerInfo.department,
    position: state.customerInfo.position,
    phone_number: state.customerInfo.phoneNumber!,
    fax_number: state.customerInfo.faxNumber,
    email: state.customerInfo.email!,
    postal_code: state.customerInfo.postalCode!,
    addr_pref: state.customerInfo.addrPref!,
    addr_city: state.customerInfo.addrCity!,
    addr_street:
        state.customerInfo.addrStreet + (state.customerInfo.addrOther || ""),

    auth_customer_id: state.loginInfo.customerId,
    auth_user_last_name: state.loginInfo.nameLastName,
    auth_user_first_name: state.loginInfo.nameFirstName,
    auth_fax_number: state.loginInfo.faxNumber,
    auth_email: state.loginInfo.email,
    auth_other_id_code: state.loginInfo.otherIdCode,
    auth_comment: state.loginInfo.comment,

    approver_dept_or_name: state.approverInfo.deptOrName,
    approver_email: state.approverInfo.email,

    billing_company_name: state.billingInfo.companyName!,
    billing_company_name_kana: state.billingInfo.companyNameKana!,
    billing_department: state.billingInfo.department,
    billing_last_name: state.billingInfo.nameLastName!,
    billing_first_name: state.billingInfo.nameFirstName!,
    billing_phone_number: state.billingInfo.phoneNumber!,
    billing_email: state.billingInfo.email!,
    billing_postal_code: state.billingInfo.postalCode!,
    billing_addr_pref: state.billingInfo.addrPref!,
    billing_addr_city: state.billingInfo.addrCity!,
    billing_addr_street:
        state.billingInfo.addrStreet + (state.billingInfo.addrOther || ""),
    billing_method: state.billingInfo.billingMethod!,
    billing_send_to: state.billingInfo.billingSendTo!,
    billing_other_email:
        state.billingInfo.billingSendTo === "その他"
            ? state.billingInfo.otherEmail
            : undefined,
    billing_other_email2:
        state.billingInfo.billingSendTo === "その他"
            ? state.billingInfo.otherEmail2
            : undefined,
    billing_other_email3:
        state.billingInfo.billingSendTo === "その他"
            ? state.billingInfo.otherEmail3
            : undefined,
    billing_other_email4:
        state.billingInfo.billingSendTo === "その他"
            ? state.billingInfo.otherEmail4
            : undefined,
    billing_other_email5:
        state.billingInfo.billingSendTo === "その他"
            ? state.billingInfo.otherEmail5
            : undefined,

    payment_method: state.billingInfo.paymentMethod!,

    want_download_statement: state.billingInfo.wantDownloadStatement,
    want_split_with_statement_splitting_code:
        state.billingInfo.wantSplitWithStatementSplittingCode,
    want_display_breakdown_code:
        productModel.ruleBreakdownCodeDisplay.defaultValue,
});
