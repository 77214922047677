<template>
    <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useResourcesStore } from "./store/resources";

export default defineComponent({
    name: "App",
    setup() {
        useResourcesStore().init();
    },
});
</script>

<style lang="scss" scoped>
.app-init-awaiting {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;
    box-sizing: border-box;

    width: 100vw;
    height: 100vh;
}
.app-init-error {
    font-size: 1rem;
    line-height: 1.4rem;
    color: #f66;
}
</style>
