<template>
    <span class="keep-text"><slot></slot></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "KeepText",
});
</script>

<style lang="scss" scoped>
.keep-text {
    word-break: keep-all;
}
</style>
