import * as Sentry from "@sentry/browser";
import * as VueRouter from "vue-router";

import FirstConfirmPage from "../components/contexts/pages/Confirm/FirstConfirmPage.vue";
import SecondConfirmPage from "../components/contexts/pages/Confirm/SecondConfirmPage.vue";
import NotFoundPage from "../components/contexts/pages/Errors/NotFoundPage.vue";
import FinishedPage from "../components/contexts/pages/FinishedPage.vue";
import FirstFormPage from "../components/contexts/pages/Form/FirstFormPage/index.vue";
import SecondFormPage from "../components/contexts/pages/Form/SecondFormPage/index.vue";
import { usePagination } from "../store/pagination";
import { useResourcesStore } from "../store/resources";
import { useScrollStore } from "../store/scroll";
import { useWebFormStore } from "../store/webform";
import { ROUTE_PATHS } from "./paths";

const routes: VueRouter.RouteRecordRaw[] = [
    {
        path: ROUTE_PATHS["/service/:productAlias"],
        redirect: (to) => ({
            path: ROUTE_PATHS.FIRST_FORM,
            query: {
                recept_service_alias: to.params.productAlias,
            },
        }),
    },
    {
        path: ROUTE_PATHS.FIRST_FORM,
        component: FirstFormPage,
    },
    {
        path: ROUTE_PATHS.SECOND_FORM,
        component: SecondFormPage,
    },
    {
        path: ROUTE_PATHS.FIRST_CONFIRM,
        component: FirstConfirmPage,
    },
    {
        path: ROUTE_PATHS.SECOND_CONFIRM,
        component: SecondConfirmPage,
    },
    {
        path: ROUTE_PATHS.FINISHED,
        component: FinishedPage,
    },
    {
        path: "/:notfound(.*)",
        component: NotFoundPage,
    },
];

export const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const scrollStore = useScrollStore();
        const scrollTop = scrollStore.scrollTop;
        scrollStore.$reset();

        console.debug("scrollBehavior", {
            to,
            from,
            savedPosition,
            scrollStoredTop: scrollTop,
        });

        const fromFinishedPage = from.path === ROUTE_PATHS.FINISHED;

        if (savedPosition && !fromFinishedPage) {
            return savedPosition;
        } else {
            return { top: scrollTop };
        }
    },
});

router.beforeEach((to, from) => {
    const paginates = usePagination();
    const formStore = useWebFormStore();

    console.debug("router.beforeEach", { to: { ...to }, from: { ...from } });

    if (
        from.path === ROUTE_PATHS.FINISHED &&
        paginates.state.visited.finished
    ) {
        formStore.$reset();
        paginates.$reset();
    }

    switch (to.path) {
        case ROUTE_PATHS.FIRST_FORM: {
            // first form 訪問済み
            paginates.visitFirstFormPage();
            break;
        }
        case ROUTE_PATHS.SECOND_FORM: {
            if (!paginates.state.visited.firstForm) {
                // first form を訪問済みでないといけない.
                return { path: ROUTE_PATHS.FIRST_FORM, replace: true };
            } else {
                paginates.visitSecondFormPage();
            }
            break;
        }
        case ROUTE_PATHS.FIRST_CONFIRM: {
            if (!paginates.state.visited.secondForm) {
                // second form を訪問済みでないといけない.
                return { path: ROUTE_PATHS.FIRST_FORM, replace: true };
            } else {
                paginates.visitFirstConfirmPage();
            }
            break;
        }
        case ROUTE_PATHS.SECOND_CONFIRM: {
            // first confirm を訪問済みでないといけない.
            if (!paginates.state.visited.firstConfirm) {
                // first form へ戻す
                return { path: ROUTE_PATHS.FIRST_FORM, replace: true };
            } else {
                paginates.visitSecondConfirmPage();
            }
            break;
        }
        case ROUTE_PATHS.FINISHED: {
            // second confirm を訪問済みでないといけない.
            if (!paginates.state.visited.secondConfirm) {
                // first form へ戻す
                return { path: ROUTE_PATHS.FIRST_FORM, replace: true };
            } else {
                paginates.visitFinishedPage();
            }
        }
    }
});

router.afterEach(async (to, from) => {
    const resourceStore = useResourcesStore();
    const scrollStore = useScrollStore();

    switch (to.path) {
        case ROUTE_PATHS.FIRST_FORM: {
            console.debug({
                recept_service_alias: to.query.recept_service_alias,
                recept_service: to.query.recept_service,
            });

            // URL query string で商品が指定されている場合
            if (to.query.recept_service_alias || to.query.recept_service) {
                Sentry.setContext("PreDefinedProduct", {
                    recept_service_alias: to.query.recept_service_alias,
                    recept_service: to.query.recept_service,
                });

                // 初期化 promise を待機
                await (resourceStore.state.product.promise ||
                    resourceStore.init());

                const prod = (() => {
                    if (to.query.recept_service_alias) {
                        // find by service Alias
                        return resourceStore.productItems.find(
                            (p) =>
                                p.alias_for_meme_webform ===
                                to.query.recept_service_alias
                        );
                    } else if (to.query.recept_service) {
                        // find by service Name
                        return resourceStore.productItems.find(
                            (p) => p.name === to.query.recept_service
                        );
                    }
                })();
                console.debug({ prod });

                if (prod) {
                    const formStore = useWebFormStore();
                    formStore.setApplicationInfoState({ product: prod.id });
                    scrollStore.setScrollTop(window.scrollY);
                    router.replace({ path: ROUTE_PATHS.FIRST_FORM });
                }
            }
        }
    }
});
