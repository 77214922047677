import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { retrieveProducts } from "../../api";
import { Product } from "../../api/interfaces";

const genInitState = () => ({
    product: {
        items: [],
        promise: undefined,
        initialized: false,
        errors: [],
    },
});

/**
 * @package
 */
export const useResourcesStore = defineStore("resources", () => {
    const state = ref<ResourcesState>(genInitState());

    const $reset = () => (state.value = genInitState());

    const productItems = computed(() => state.value.product.items);

    const getProductById = computed(
        () => (prodId: string) =>
            state.value.product.items.find((p) => p.id === prodId)
    );

    const init = async () => {
        try {
            if (!state.value.product.promise) {
                state.value.product.promise = retrieveProducts();
            }
            const prods = await state.value.product.promise;
            state.value.product.items = prods.data;
        } catch (e: unknown) {
            console.debug("error", e);
            if (isAxiosError(e)) {
                state.value.product.errors = [e.response?.data.message];
            } else if (e instanceof Error) {
                state.value.product.errors = [e.message];
            } else {
                state.value.product.errors = [`${e}`];
            }
            throw e;
        } finally {
            state.value.product.initialized = true;
        }
    };

    return { state, $reset, productItems, init, getProductById };
});

type ResourcesState = {
    product: {
        items: Product[];
        promise?: Promise<AxiosResponse<Product[]>>;
        initialized: boolean;
        errors: string[];
    };
};
