<template>
    <div class="first-confirm-page">
        <GlobalHeader />
        <CenteredLayout>
            <div class="first-confirm">
                <div class="form-description">
                    <p>
                        ※ブラウザの「戻る」「進む」ボタンはご使用にならないようお願いいたします。
                    </p>
                    <p>以下の内容を確認後「次へ」を押してください。</p>
                </div>

                <FormSection title="請求先情報">
                    <!-- 会社名 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.companyName
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.companyName }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 会社名フリガナ -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.companyNameKana
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.companyNameKana }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 部署 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.department
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.department }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- お名前 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.name
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.nameLastName }}
                                {{ state.nameFirstName }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 電話番号 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.phoneNumber
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.phoneNumber }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 郵便番号 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.postalCode
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.postalCode }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 住所 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.address
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.addrPref }}{{ state.addrCity
                                }}{{ state.addrStreet }}{{ state.addrOther }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- メールアドレス -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.email
                        }}</template>
                        <template #content>
                            <p class="confirming-text">{{ state.email }}</p>
                        </template>
                    </FormFieldGroup>
                    <!-- 部署名、プラン名など -->
                    <!-- <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.deptOrPlanNameOrEtc
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.deptOrPlanNameOrEtc }}
                            </p>
                        </template>
                    </FormFieldGroup> -->
                    <!-- 請求方法 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.billingMethod
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.billingMethod }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 請求宛先 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.billingSendTo
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.billingSendTo }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- <FormFieldGroup v-if="billingSendTo.length > 0">
                        <template #title>{{
                            labels.billingInfo.billingSendTo
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                <span v-for="(msg, i) in billingSendTo" :key="i"
                                    >{{ msg
                                    }}<template
                                        v-if="billingSendTo.length !== i + 1"
                                        >, &nbsp;</template
                                    >
                                </span>
                            </p>
                        </template>
                    </FormFieldGroup> -->
                    <!-- その他メールアドレス -->
                    <FormFieldGroup v-if="state.billingSendTo === 'その他'">
                        <template #title>
                            <!-- eslint-disable vue/no-v-html -->
                            <span
                                class="display-contents"
                                v-html="labels.billingInfo.otherEmail"
                            ></span>
                            <!-- eslint-enable vue/no-v-html -->
                        </template>
                        <template #content>
                            <ol class="confirming-list">
                                <li v-for="(email, i) in otherEmails" :key="i">
                                    <p class="confirming-text">
                                        {{ email }}
                                    </p>
                                </li>
                            </ol>
                        </template>
                    </FormFieldGroup>
                    <!-- 支払方法 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.billingInfo.paymentMethod
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.paymentMethod }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 利用明細、請求内訳等 -->
                    <FormFieldGroup v-if="showWantOptions">
                        <template #title>{{
                            labels.billingInfo.wantOptions
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                <span v-if="!!wantDownloadStatement">{{
                                    wantDownloadStatement
                                }}</span>
                                <span
                                    v-if="!!wantSplitWithStatementSplittingCode"
                                    >{{
                                        wantSplitWithStatementSplittingCode
                                    }}</span
                                >
                            </p>
                        </template>
                    </FormFieldGroup>
                </FormSection>

                <div class="submit-button-container">
                    <BackButton @click="handleClickBack" />
                    <SubmitButton @click="handleClickSubmit" />
                </div>
            </div>
        </CenteredLayout>
        <GlobalFooter />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { ROUTE_PATHS } from "../../../../router/paths";
import { useWebFormStore } from "../../../../store/webform";
import BackButton from "../../../ui/Atoms/BackButton.vue";
import FormFieldGroup from "../../../ui/Atoms/FormFieldGroup.vue";
import GlobalFooter from "../../../ui/Atoms/GlobalFooter.vue";
import GlobalHeader from "../../../ui/Atoms/GlobalHeader.vue";
import SubmitButton from "../../../ui/Atoms/SubmitButton.vue";
import FormSection from "../../../ui/Molecures/FormSection.vue";
import CenteredLayout from "../../../ui/Templates/CenteredLayout.vue";

export default defineComponent({
    name: "SecondConfirmPage",
    components: {
        GlobalHeader,
        CenteredLayout,
        GlobalFooter,
        FormSection,
        FormFieldGroup,
        BackButton,
        SubmitButton,
    },
    props: {
        storybook: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const store = useWebFormStore();
        const state = computed(() => store.state.billingInfo);
        const labels = store.labels;
        const prodModel = store.prodModel;
        const sendFormData = store.sendFormData;

        return { state, labels, prodModel, sendFormData };
    },
    computed: {
        productModel() {
            return this.prodModel();
        },
        wantDownloadStatement() {
            return this.state.wantDownloadStatement
                ? this.labels.billingInfo.wantDownloadStatement
                : "";
        },
        wantSplitWithStatementSplittingCode() {
            return this.state.wantSplitWithStatementSplittingCode
                ? this.labels.billingInfo.wantSplitWithStatementSplittingCode
                : "";
        },
        showWantOptions() {
            return (
                !!this.wantDownloadStatement ||
                !!this.wantSplitWithStatementSplittingCode
            );
        },
        otherEmails() {
            return [
                this.state.otherEmail,
                this.state.otherEmail2,
                this.state.otherEmail3,
                this.state.otherEmail4,
                this.state.otherEmail5,
            ].filter(Boolean);
        },
    },
    methods: {
        handleClickBack() {
            if (this.$props.storybook) {
                alert("前のページへ戻る");
                return;
            }

            this.$router.push({ path: ROUTE_PATHS.FIRST_CONFIRM });
        },
        handleClickSubmit() {
            if (this.$props.storybook) {
                alert("送信して、成功したら次の画面へ進む");
                return;
            }

            try {
                this.sendFormData();
            } catch (e: unknown) {
                if (e instanceof Error) {
                    alert(e.message);
                    throw e;
                }
            }
            this.$router.push({ path: ROUTE_PATHS.FINISHED });
        },
    },
});
</script>

<style lang="scss" scoped>
.first-confirm-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100vh;

    .first-confirm {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;

        .form-description {
            margin: 0 0 2.25rem;
        }

        ol.confirming-list {
            margin: 0;
            padding-left: 1.5rem;
            & > li {
                margin-bottom: 0.4rem;
            }
        }

        .confirming-text {
            font-size: 1rem;
            margin: 0;
            min-height: 1rem;
            & > span {
                display: inline-block;
                font-size: 0.85rem;
                margin: 0.125rem 0;
            }
        }
    }

    .submit-button-container {
        margin: 3rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        & > * {
            margin-bottom: 1rem;
        }
    }
}
.display-contents {
    display: contents;
}
</style>
