/**
 * 簡易的なメールアドレス形式文字列に一致 (Salesforce の email 型項目に入る `aaa@bbb.ccc` の形)
 */
export const EMAIL_PATTERN_REGEXP =
    /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
/**
 * 空を許容する場合
 */
export const EMAIL_OPTIONAL_PATTERN_REGEXP =
    /^([a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)?$/;

export const EMAIL_USER_PATTERN_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+/;

/**
 * 1文字以上の全角または半角のカタカナ又は半角数値で始まり, 0 文字以上の全角または半角のカタカナと一部記号と半角数値で終わる文字列に一致
 *
 * \u309B: 全角濁点
 * \u309C: 全角半濁点
 * \u30A1-\u30FE: 全角カタカナ、全角カタカナ中点、全角カタカナ長音、全角カタカナ繰り返し記号、全角カタカナ繰り返し記号 (濁点)
 * \u3000: 全角スペース
 * \uFF0D: 全角ハイフン
 * \u002D: 半角ハイフン
 *
 * ref: Unicode - CyberLibrarian https://www.asahi-net.or.jp/~ax2s-kmtn/ref/unicode/index.html
 */
export const KATAKANA_REGEXP =
    /^[\u30A1-\u30FA]+[\u309B\u309C\u30A1-\u30FE\u3000\uFF0D\u002D]*$/;

/**
 * 上から半角数値を除外
 */
export const KATAKANA_REGEXP_EXCLUDE_NUM =
    /^[\u30A1-\u30FA]+[\u309B\u309C\u30A1-\u30FE\u3000\uFF0D\u002D]*$/;

export const POSTALCODE_REGEXP = /^\d{7,8}$/;

export const PHONE_NUMBER_REGEXP = /^\d{10,11}$/;
export const PHONE_NUMBER_OPTIONAL_REGEXP = /^\d{0,11}$/;
export const FAX_NUMBER_REGEXP = /^\d{10,11}$/;
export const FAX_NUMBER_OPTIONAL_REGEXP = /^\d{0,11}$/;
