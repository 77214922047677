<template>
    <FormSection
        v-if="
            productModel?.inputableLoginInfoLastName ||
            productModel?.inputableLoginInfoFirstName ||
            productModel?.inputableLoginIdType ||
            productModel?.inputableLoginInfoComment
        "
        title="ログイン情報"
        class="login-info-section"
    >
        <div v-if="!nonCopyFields">
            <!-- お客様情報からコピー -->
            <Checkbox
                name="login_info_copy_from_customer_info"
                :label="labels.loginInfo.copyFromCustomerInfo"
                :model-value="state.copyFromCustomerInfo"
                @update:model-value="
                    handleUpdate('copyFromCustomerInfo', $event)
                "
            />
        </div>

        <!-- お客様番号 -->
        <FormFieldGroup
            v-if="productModel?.inputableCustomerId"
            :valid="!!state.customerId && !errors.customerId"
            :error="!!errors.customerId"
            :required="productModel.requiredCustomerId"
        >
            <template #title>{{ labels.loginInfo.customerId }}</template>
            <template #content>
                <InputField
                    block
                    name="login_info_customer_id"
                    :required="productModel.requiredCustomerId"
                    :model-value="state.customerId"
                    :valid="!!state.customerId && !errors.customerId"
                    :error="!!errors.customerId"
                    @update:model-value="handleUpdate('customerId', $event)"
                    @keyup="handleUpdate('customerId', $event)"
                />
            </template>
            <template v-if="!!errors.customerId" #sub-content>
                <FieldErrorText>
                    {{ errors.customerId }}
                </FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- お名前 -->
        <FormFieldGroup
            v-if="
                productModel?.inputableLoginInfoLastName ||
                productModel?.inputableLoginInfoFirstName
            "
            :valid="
                !!state.nameLastName &&
                !errors.nameLastName &&
                !!state.nameFirstName &&
                !errors.nameFirstName
            "
            :error="!!errors.nameLastName || !!errors.nameFirstName"
            :required="
                productModel.requiredLoginInfoLastName ||
                productModel.requiredLoginInfoFirstName
            "
        >
            <template #title>{{ labels.loginInfo.name }}</template>
            <template #content>
                <div class="flex-row-align-centered-content-start">
                    <!-- 姓 -->
                    <div
                        v-if="productModel?.inputableLoginInfoLastName"
                        class="flex-row-align-centered-content-start"
                    >
                        <span>{{ labels.loginInfo.nameLastName }}:&nbsp;</span>
                        <InputField
                            name="login_info_name_last_name"
                            style="width: 10rem"
                            :readonly="state.copyFromCustomerInfo"
                            placeholder="例）田中"
                            :model-value="state.nameLastName"
                            :valid="
                                !!state.nameLastName && !errors.nameLastName
                            "
                            :error="!!errors.nameLastName"
                            :required="productModel.requiredLoginInfoLastName"
                            @update:model-value="
                                handleUpdate('nameLastName', $event)
                            "
                            @keyup="handleUpdate('nameLastName', $event)"
                        />
                    </div>
                    <!-- 名 -->
                    <div
                        v-if="productModel?.inputableLoginInfoFirstName"
                        class="flex-row-align-centered-content-start"
                    >
                        <span>{{ labels.loginInfo.nameFirstName }}:&nbsp;</span>
                        <InputField
                            name="login_info_name_first_name"
                            style="width: 10rem"
                            :readonly="state.copyFromCustomerInfo"
                            placeholder="例）太郎"
                            :model-value="state.nameFirstName"
                            :valid="
                                !!state.nameFirstName && !errors.nameFirstName
                            "
                            :error="!!errors.nameFirstName"
                            :required="productModel.requiredLoginInfoFirstName"
                            @update:model-value="
                                handleUpdate('nameFirstName', $event)
                            "
                            @keyup="handleUpdate('nameFirstName', $event)"
                        />
                    </div>
                </div>
            </template>
            <template
                v-if="!!errors.nameLastName || !!errors.nameFirstName"
                #sub-content
            >
                <FieldErrorText v-if="!!errors.nameLastName">{{
                    errors.nameLastName
                }}</FieldErrorText>
                <FieldErrorText v-if="!!errors.nameFirstName">{{
                    errors.nameFirstName
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 識別コード: FAX番号 -->
        <FormFieldGroup
            v-if="productModel.loginIdTypeIsFax"
            :valid="!!state.faxNumber && !errors.faxNumber"
            :error="!!errors.faxNumber"
            :required="productModel.loginIdTypeIsFax"
        >
            <template #title>{{ labels.loginInfo.faxNumber }}</template>
            <template #content>
                <InputField
                    :readonly="state.copyFromCustomerInfo"
                    :required="productModel.loginIdTypeIsFax"
                    placeholder="例）0311112222"
                    name="login_info_fax_number"
                    :model-value="state.faxNumber"
                    :valid="!!state.faxNumber && !errors.faxNumber"
                    :error="!!errors.faxNumber"
                    @update:model-value="handleUpdate('faxNumber', $event)"
                    @keyup="handleUpdate('faxNumber', $event)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角数字のみ、ハイフンなし</HelpText>
                <FieldErrorText v-if="!!errors.faxNumber">{{
                    errors.faxNumber
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 識別コード: メールアドレス -->
        <FormFieldGroup
            v-else-if="productModel.loginIdTypeIsEmail"
            :valid="!!state.email && !errors.email"
            :error="!!errors.email"
            :required="productModel.loginIdTypeIsEmail"
        >
            <template #title>{{ labels.loginInfo.email }}</template>
            <template #content>
                <InputField
                    :readonly="state.copyFromCustomerInfo"
                    :required="productModel.loginIdTypeIsEmail"
                    placeholder="例）xxxx@example.com"
                    block
                    name="login_info_email"
                    :pattern="emailPattern"
                    :model-value="state.email"
                    :valid="!!state.email && !errors.email"
                    :error="!!errors.email"
                    @update:model-value="handleUpdate('email', $event)"
                    @keyup="handleUpdate('email', $event)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角英数記号のみ</HelpText>
                <FieldErrorText v-if="!!errors.email">{{
                    errors.email
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 識別コード: 任意 -->
        <FormFieldGroup
            v-else-if="productModel.loginIdTypeIsOptional"
            :valid="!!state.otherIdCode && !errors.otherIdCode"
            :error="!!errors.otherIdCode"
        >
            <template #title>{{ labels.loginInfo.otherIdCode }}</template>
            <template #content>
                <InputField
                    placeholder="例）xxxx@example.com"
                    block
                    name="login_info_other_id_code"
                    :model-value="state.otherIdCode"
                    :valid="!!state.otherIdCode && !errors.otherIdCode"
                    :error="!!errors.otherIdCode"
                    :pattern="optionalEmailPattern"
                    @update:model-value="handleUpdate('otherIdCode', $event)"
                    @keyup="handleUpdate('otherIdCode', $event)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角英数記号のみ</HelpText>
                <FieldErrorText v-if="!!errors.otherIdCode">{{
                    errors.otherIdCode
                }}</FieldErrorText></template
            >
        </FormFieldGroup>

        <!-- 請求内訳コメント -->
        <FormFieldGroup
            v-if="productModel.inputableLoginInfoComment"
            :valid="!!state.comment && !errors.comment"
            :error="!!errors.comment"
            :required="productModel.requiredLoginInfoComment"
        >
            <template #title>{{ labels.loginInfo.comment }}</template>
            <template #content>
                <InputField
                    block
                    name="login_info_comment"
                    :required="productModel.requiredLoginInfoComment"
                    :model-value="state.comment"
                    :valid="!!state.comment && !errors.comment"
                    :error="!!errors.comment"
                    @update:model-value="handleUpdate('comment', $event)"
                    @keyup="handleUpdate('comment', $event)"
                />
            </template>
            <template v-if="!!errors.comment" #sub-content>
                <FieldErrorText>{{ errors.comment }}</FieldErrorText>
            </template>
        </FormFieldGroup>
    </FormSection>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import {
    EMAIL_OPTIONAL_PATTERN_REGEXP,
    EMAIL_PATTERN_REGEXP,
} from "../../../../../../libs/patterns";
import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import Checkbox from "../../../../../ui/Atoms/Checkbox.vue";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import HelpText from "../../../../../ui/Atoms/HelpText.vue";
import InputField from "../../../../../ui/Atoms/InputField.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";

type LoginInfoState = WebFormState["loginInfo"];

export default defineComponent({
    name: "LoginInfoSection",
    components: {
        FormSection,
        FormFieldGroup,
        Checkbox,
        InputField,
        FieldErrorText,
        HelpText,
    },
    setup() {
        const formStore = useWebFormStore();
        const state = computed(() => formStore.state.loginInfo);
        const billingInfoState = computed(() => formStore.state.billingInfo);
        const loginInfoErrors = formStore.loginInfoErrors;
        const setLoginInfoState = formStore.setLoginInfoState;
        const prodModel = formStore.prodModel;
        const labels = formStore.labels;
        const billingInfoErrors = formStore.billingInfoErrors;

        return {
            state,
            billingInfoState,
            loginInfoErrors,
            setLoginInfoState,
            prodModel,
            labels,
            billingInfoErrors,
        };
    },
    computed: {
        errors() {
            return this.loginInfoErrors();
        },
        billingErrors() {
            return this.billingInfoErrors();
        },
        productModel() {
            return this.prodModel();
        },
        emailPattern() {
            return EMAIL_PATTERN_REGEXP.toString().replace(/^\/|\/$/g, "");
        },
        optionalEmailPattern() {
            return EMAIL_OPTIONAL_PATTERN_REGEXP.toString().replace(
                /^\/|\/$/g,
                ""
            );
        },
        nonCopyFields() {
            return (
                !this.productModel?.inputableCustomerId &&
                !this.productModel?.inputableLoginInfoLastName &&
                !this.productModel?.inputableLoginInfoFirstName &&
                !this.productModel?.loginIdTypeIsFax &&
                !this.productModel?.loginIdTypeIsEmail &&
                !this.productModel?.loginIdTypeIsOptional
            );
        },
    },
    methods: {
        handleUpdate(
            key: keyof LoginInfoState,
            value: LoginInfoState[keyof LoginInfoState]
        ) {
            this.setLoginInfoState({
                [key]: value,
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.login-info-section {
    .flex-row-align-centered-content-start {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 1rem;
    }
}
</style>
