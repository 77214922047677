<template>
    <div class="error-404-page">
        <GlobalHeader />
        <CenteredLayout class="centered-layout">
            <div class="contents">
                <h1>404 Not found</h1>
                <h2>ご指定のページは存在しません。</h2>
            </div>
        </CenteredLayout>
        <GlobalFooter />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import GlobalFooter from "../../../ui/Atoms/GlobalFooter.vue";
import GlobalHeader from "../../../ui/Atoms/GlobalHeader.vue";
import CenteredLayout from "../../../ui/Templates/CenteredLayout.vue";

export default defineComponent({
    name: "NotFoundPage",
    components: { CenteredLayout, GlobalFooter, GlobalHeader },
});
</script>

<style lang="scss" scoped>
.error-404-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100vh;

    .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        line-height: 1em;
        margin: 0.5em 0 0.8em;
    }

    h2 {
        line-height: 1em;
        margin: 0.5em 0 0.8em;
    }
}
</style>
