<template>
    <header class="global-header">
        <div class="header-inner">
            <h1 class="header-logo">
                <router-link to="/" class="logo-link">ネクスウェイ</router-link>
            </h1>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "GlobalHeader",
});
</script>

<style lang="scss" scoped>
.global-header {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-bottom: 30px;
    border-bottom: 4px solid #25bed5;

    background: #fff;

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
    }

    .header-inner {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        width: 800px;
        padding: 18px 0 17px 0;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 10px;
        }
    }

    .header-logo {
        margin: 0;
        padding: 0;

        @media screen and (max-width: 768px) {
            width: 120px;
        }

        & > * {
            display: inline-block;
            text-decoration: underline;
            color: #333;

            text-indent: -999rem;

            width: 170px;
            max-width: 100%;
            height: 53.83586082px;
            background: url("/images/logo.png") left center no-repeat;
            background-size: contain;
        }
    }
}
</style>
