<template>
    <div class="radio-buttons">
        <fieldset :disabled="disabled">
            <template v-for="(item, i) in items" :key="i">
                <label>
                    <input
                        type="radio"
                        :name="name"
                        :value="item.value"
                        :required="required"
                        :checked="item.value === modelValue"
                        @change="
                            $emit(
                                'update:modelValue',
                                ($event.currentTarget as HTMLInputElement).value
                            )
                        "
                        @keydown.stop
                        @keyup.stop
                        @keypress.stop
                    />
                    {{ item.label }}
                </label>
            </template>
        </fieldset>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

type Radio = {
    value: string;
    label: string;
    checked?: boolean;
};

export default defineComponent({
    name: "RadioButtons",
    props: {
        name: {
            type: String,
            required: true,
        },
        items: {
            type: Object as PropType<Radio[]>,
            default: () => [] as PropType<Radio[]>,
        },
        modelValue: {
            type: String,
            default: undefined,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
});
</script>

<style lang="scss" scoped>
.radio-buttons {
    & > fieldset {
        margin: 0;
        padding: 0;
        border: none;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > label {
            margin-right: 1rem;
            cursor: pointer;
            color: #333;
            font-size: 0.85rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0.425rem 0.25rem;

            &:hover {
                background-color: rgba(0, 0, 0, 0.025);
                border-radius: 5px;
            }

            & > input[type="radio"] {
                margin-right: 0.3rem;
                cursor: inherit;
            }
        }
    }
}
</style>
