<template>
    <p class="help-text"><slot></slot></p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "HelpText",
});
</script>

<style lang="scss" scoped>
.help-text {
    color: #666;
    font-size: 0.8rem;
    line-height: 1rem;
    margin: 0;
    margin-bottom: 0.2rem;
}
</style>
