<template>
    <select
        :id="id"
        class="select-field"
        :class="{ valid, error, block, readonly }"
        :name="name"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :disabled="disabled"
        :multiple="multiple"
        :required="required"
        :size="size"
        @change="handleUpdate"
    >
        <template v-for="optOrOptGrp in options" :key="optOrOptGrp.key">
            <template v-if="'options' in optOrOptGrp">
                <!-- typeof OptGroup -->
                <optgroup
                    :disabled="optOrOptGrp.disabled"
                    :label="optOrOptGrp.label"
                >
                    <option
                        v-for="opt in optOrOptGrp.options"
                        :key="opt.key"
                        :value="opt.value"
                        :disabled="
                            opt.disabled ||
                            ($props.readonly
                                ? $props.modelValue !== opt.value
                                : false)
                        "
                        :selected="opt.value === modelValue"
                    >
                        {{ opt.label }}
                    </option>
                </optgroup>
            </template>
            <template v-else>
                <!-- typeof Option -->
                <option
                    :value="optOrOptGrp.value"
                    :disabled="
                        optOrOptGrp.disabled ||
                        ($props.readonly
                            ? $props.modelValue !== optOrOptGrp.value
                            : false)
                    "
                    :selected="optOrOptGrp.value === modelValue"
                >
                    {{ optOrOptGrp.label }}
                </option>
            </template>
        </template>
    </select>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AutocompleteValue } from "../../../types/components";

export type Option = {
    key: string | number;
    value: string | number;
    label: string;
    disabled?: boolean;
};

export type OptGroup = {
    key: string | number;
    disabled?: boolean;
    label: string;
    options: Option[];
};

export default defineComponent({
    name: "SelectField",
    props: {
        modelValue: {
            type: String,
            required: false,
            default: "",
        },
        valid: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },

        block: {
            type: Boolean,
            default: false,
        },

        options: {
            type: Array as PropType<(Option | OptGroup)[]>,
            required: false,
            default: () => [],
        },
        autocomplete: {
            type: String as PropType<AutocompleteValue>,
            required: false,
            default: undefined,
        },
        autofocus: {
            type: Boolean,
            required: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        multiple: {
            type: Boolean,
            required: false,
        },
        name: {
            type: String,
            required: false,
            default: undefined,
        },
        required: {
            type: Boolean,
            required: false,
        },
        size: {
            type: Number,
            required: false,
            default: undefined,
        },
        id: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    emits: ["update:modelValue"],
    methods: {
        handleUpdate($event: Event) {
            this.$emit(
                "update:modelValue",
                ($event.currentTarget as HTMLSelectElement).value
            );
        },
    },
});
</script>

<style lang="scss" scoped>
$valid-color: #e9f8fb;

.select-field {
    display: inline-block;
    width: auto;
    padding: 0.4rem;

    border: 1px solid #aaa;
    border-radius: 3px;
    box-sizing: border-box;

    text-overflow: ellipsis;

    cursor: pointer;

    &.block {
        display: block;
        width: 100%;
    }

    &.valid {
        background-color: $valid-color;
    }

    &.error {
        background-color: #f66;
    }

    &.readonly:not(:disabled) {
        background-color: #eee;
        color: #757575;
        cursor: default;
    }
}
</style>
