import { isAxiosError } from "axios";
import merge from "lodash.merge";
import { defineStore } from "pinia";
import { computed, Ref, ref } from "vue";
import { ValidationError } from "yup";

import {
    retrieveAddrByPostalCode as retrieveAddrByPostalCodeApi,
    submitForm,
} from "../../api";
import {
    SubmitFormErrorResponse,
    SubmitFormRequest,
} from "../../api/interfaces";
import { NOT_AVAILABLE } from "../../libs/company-types";
import { ProductModel } from "../../models/product-model";
import { useResourcesStore } from "../resources";
import { genFormDataStruct, genInitialState } from "./helpers";
import {
    ApplicationInfoSection,
    ApproverInfoSection,
    BillingInfoSection,
    CustomerInfoSection,
    ErrorsObj,
    FormSectionState,
    LoginInfoSection,
    NxwSalesPersonSection,
    ObjectEntries,
    PrivacyInfoSection,
    Validations,
    WebFormState,
} from "./interfaces";
import { Labels } from "./labels";
import { validators } from "./validators";

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * @package
 */
export const useWebFormStore = defineStore("webForm", () => {
    const state = ref<WebFormState>(genInitialState());

    const $reset = () => (state.value = genInitialState());

    const detectErrors = <T extends FormSectionState>(
        section: T,
        validators: Validations<Extract<keyof T, string>>,
        force = false
    ): ErrorsObj<T> => {
        return (Object.entries(section) as ObjectEntries<T>)
            .map(([key, value]) => {
                let error: string | undefined;
                try {
                    if (typeof value !== "undefined" || force) {
                        validators[key].validateSync(value, {
                            context: { state: state.value, force },
                        });
                        error = "";
                    }
                } catch (e: unknown) {
                    if (e instanceof ValidationError) {
                        error = e.message;
                    }
                }
                return { [key]: error } as ErrorsObj<T>;
            })
            .reduce(
                (acm, keyValue) => ({ ...acm, ...keyValue }),
                {} as ErrorsObj<T>
            );
    };
    const existSomeErrors = (errsObj: ErrorsObj<FormSectionState>) => {
        return (
            Object.entries(errsObj) as ObjectEntries<
                typeof privacyInfoErrors.value
            >
        ).some(([_, errMsg]) => !!errMsg);
    };

    /**
     * getters
     */
    const actualCompanyName = computed(() => {
        const companyName = state.value.customerInfo.companyName;
        if (!companyName) return "";

        const { prefix, name, suffix } = companyName;
        const p = prefix === NOT_AVAILABLE ? "" : prefix;
        const s = suffix === NOT_AVAILABLE ? "" : suffix;
        return `${p || ""}${name || ""}${s || ""}`;
    });

    const labels = computed(() => Labels);

    const privacyInfoErrors = computed(
        () => () =>
            detectErrors(
                state.value.privacyInfo,
                validators.privacyInfo,
                state.value._meta.forceValidation.privacyInfo
            )
    );
    const nxwSalesPersonErrors = computed(
        () => () =>
            detectErrors(
                state.value.nxwSalesPerson,
                validators.nxwSalesPerson,
                state.value._meta.forceValidation.nxwSalesPerson
            )
    );
    const applicationInfoErrors = computed(
        () => () =>
            detectErrors(
                state.value.applicationInfo,
                validators.applicationInfo,
                state.value._meta.forceValidation.applicationInfo
            )
    );
    const customerInfoErrors = computed(
        () => () =>
            detectErrors(
                state.value.customerInfo,
                validators.customerInfo,
                state.value._meta.forceValidation.customerInfo
            )
    );
    const loginInfoErrors = computed(
        () => () =>
            detectErrors(
                state.value.loginInfo,
                validators.loginInfo,
                state.value._meta.forceValidation.loginInfo
            )
    );
    const approverInfoErrors = computed(
        () => () =>
            detectErrors(
                state.value.approverInfo,
                validators.approverInfo,
                state.value._meta.forceValidation.approverInfo
            )
    );
    const billingInfoErrors = computed(
        () => () =>
            detectErrors(
                state.value.billingInfo,
                validators.billingInfo,
                state.value._meta.forceValidation.billingInfo
            )
    );

    const existPrivacyInfoErrors = computed(() =>
        existSomeErrors(privacyInfoErrors.value())
    );
    const existNxwSalesPersonErrors = computed(() =>
        existSomeErrors(nxwSalesPersonErrors.value())
    );
    const existApplicationInfoErrors = computed(() =>
        existSomeErrors(applicationInfoErrors.value())
    );
    const existCustomerInfoErrors = computed(() =>
        existSomeErrors(customerInfoErrors.value())
    );
    const existLoginInfoErrors = computed(() =>
        existSomeErrors(loginInfoErrors.value())
    );
    const existApproverInfoErrors = computed(() =>
        existSomeErrors(approverInfoErrors.value())
    );
    const existFirstFormErrors = computed(
        () =>
            existPrivacyInfoErrors.value ||
            existNxwSalesPersonErrors.value ||
            existApplicationInfoErrors.value ||
            existCustomerInfoErrors.value ||
            existLoginInfoErrors.value ||
            existApproverInfoErrors.value
    );
    const existBillingInfoErrors = computed(() =>
        existSomeErrors(billingInfoErrors.value())
    );
    const existSecondFormErrors = computed(() => existBillingInfoErrors.value);
    const existFormErrors = computed(
        () => existFirstFormErrors.value || existSecondFormErrors.value
    );
    const prodModel = computed(() => () => {
        const prodId = state.value.applicationInfo.product;
        if (!prodId) return undefined;

        const resStore = useResourcesStore();
        const prod = resStore.getProductById(prodId);
        if (!prod) return undefined;

        return ProductModel.make(prod);
    });

    /**
     * actions
     */
    const setPrivacyInfoState = (payload: Partial<PrivacyInfoSection>) =>
        (state.value.privacyInfo = merge({}, state.value.privacyInfo, payload));
    const setNxwSalesPersonState = (payload: Partial<NxwSalesPersonSection>) =>
        (state.value.nxwSalesPerson = merge(
            {},
            state.value.nxwSalesPerson,
            payload
        ));
    const setApplicationInfoState = (
        payload: Partial<ApplicationInfoSection>
    ) => {
        state.value.applicationInfo = merge(
            {},
            state.value.applicationInfo,
            payload
        );

        if (typeof payload.product !== "undefined" && !!payload.product) {
            // 商品の切り替えに伴うログイン情報入力値の制御を行なう (値のクリアなど)
            manageLoginInfo(true);
            // 商品の切り替えに伴うチェックボックス値の制御を行なう
            manageCheckboxRules();
        }
    };
    const setCustomerInfoState = (payload: Partial<CustomerInfoSection>) => {
        // // TODO: companyName, companyNameKana, postalCode, addrPref, addrCity, addrStreet が一度に渡された場合は法人番号 API によるものなので、郵便番号 API を走らせない.

        // const {
        //     companyName,
        //     companyNameKana,
        //     postalCode,
        //     addrPref,
        //     addrCity,
        //     addrStreet,
        // } = payload;

        // const updateCompanyName = !!companyName;
        // const updateCompanyNameKana = !!companyNameKana;
        // const updatePostalCode = !!postalCode;
        // const updateAddrPref = !!addrPref;
        // const updateAddrCity = !!addrCity;
        // const updateAddrStreet = !!addrStreet;
        // const byHoujinBangou =
        //     updateCompanyName &&
        //     updateCompanyNameKana &&
        //     updatePostalCode &&
        //     updateAddrPref &&
        //     updateAddrCity &&
        //     updateAddrStreet;

        // 法人格の選択に整合性を持たせるため排他処理を行う.
        const newCompanyNamePrefix = payload.companyName?.prefix;
        const newCompanyNameSuffix = payload.companyName?.suffix;
        const oldCompanyNamePrefix =
            state.value.customerInfo.companyName?.prefix;
        const oldCompanyNameSuffix =
            state.value.customerInfo.companyName?.suffix;
        const companyNamePrefixChanged =
            newCompanyNamePrefix != oldCompanyNamePrefix;
        const companyNameSuffixChanged =
            newCompanyNameSuffix != oldCompanyNameSuffix;

        if (companyNamePrefixChanged || companyNameSuffixChanged) {
            if (companyNamePrefixChanged) {
                // suffix を無効化
                if (payload.companyName?.suffix) {
                    payload.companyName.suffix = "";
                }
            } else if (companyNameSuffixChanged) {
                // prefix を無効化
                if (payload.companyName?.prefix) {
                    payload.companyName.prefix = "";
                }
            }
        }

        state.value.customerInfo = merge({}, state.value.customerInfo, payload);

        if (state.value.billingInfo.copyCustomerInfo) {
            copyCustomerInfoToBillingInfo(state);
        }
        if (state.value.loginInfo.copyFromCustomerInfo) {
            manageLoginInfo();
        }
    };
    const setLoginInfoState = (payload: Partial<LoginInfoSection>) => {
        state.value.loginInfo = merge({}, state.value.loginInfo, payload);

        if (state.value.loginInfo.copyFromCustomerInfo) {
            manageLoginInfo();
        }
    };
    const setApproverInfoState = (payload: Partial<ApproverInfoSection>) =>
        (state.value.approverInfo = merge(
            {},
            state.value.approverInfo,
            payload
        ));

    const setBillingInfoState = (
        payload: RecursivePartial<BillingInfoSection>
    ) => {
        state.value.billingInfo = merge({}, state.value.billingInfo, payload);

        if (state.value.billingInfo.copyCustomerInfo) {
            copyCustomerInfoToBillingInfo(state);
        }

        if (state.value.billingInfo.billingMethod !== "Web請求") {
            state.value.billingInfo.billingSendTo = undefined;
        }

        if (state.value.billingInfo.billingMethod === "郵送") {
            state.value.billingInfo.billingSendTo = undefined;
        }

        if (state.value.billingInfo.billingSendTo !== "その他") {
            state.value.billingInfo.otherEmail = "";
            state.value.billingInfo.otherEmail2 = "";
            state.value.billingInfo.otherEmail3 = "";
            state.value.billingInfo.otherEmail4 = "";
            state.value.billingInfo.otherEmail5 = "";
        }
    };

    const retrieveAddrsByPostalCode = async (postalCode: string) => {
        const [code1, code2] = [
            postalCode.substring(0, 3),
            postalCode.substring(3),
        ];
        return retrieveAddrByPostalCodeApi(code1, code2);
    };

    let _copyCustomerInfoToBillingInfoTimer: number | undefined = undefined;
    const copyCustomerInfoToBillingInfo = (state: Ref<WebFormState>) => {
        if (typeof _copyCustomerInfoToBillingInfoTimer !== "undefined") {
            clearTimeout(_copyCustomerInfoToBillingInfoTimer);
            _copyCustomerInfoToBillingInfoTimer = undefined;
        }
        _copyCustomerInfoToBillingInfoTimer = window.setTimeout(() => {
            state.value.billingInfo.companyName = actualCompanyName.value;
            state.value.billingInfo.companyNameKana =
                state.value.customerInfo.companyNameKana;
            state.value.billingInfo.department =
                state.value.customerInfo.department;
            state.value.billingInfo.nameLastName =
                state.value.customerInfo.nameLastName;
            state.value.billingInfo.nameFirstName =
                state.value.customerInfo.nameFirstName;
            state.value.billingInfo.phoneNumber =
                state.value.customerInfo.phoneNumber;
            state.value.billingInfo.postalCode =
                state.value.customerInfo.postalCode;
            state.value.billingInfo.addrPref =
                state.value.customerInfo.addrPref;
            state.value.billingInfo.addrCity =
                state.value.customerInfo.addrCity;
            state.value.billingInfo.addrStreet =
                state.value.customerInfo.addrStreet;
            state.value.billingInfo.addrOther =
                state.value.customerInfo.addrOther;
            state.value.billingInfo.email = state.value.customerInfo.email;
        }, 50);
    };

    let _manageLoginInfoTimer: number | undefined = undefined;

    /**
     * 条件を元に, ログイン情報セクションに含まれる情報を埋めたり削除したりする.
     *
     * - 商品の選択が変更された時
     * - お客様情報が変更された時
     * - ログイン情報セクションでコピーのチェックボックスが変更された時
     */
    const manageLoginInfo = (changedProduct = false) => {
        if (typeof _manageLoginInfoTimer !== "undefined") {
            clearTimeout(_manageLoginInfoTimer);
            _manageLoginInfoTimer = undefined;
        }
        _manageLoginInfoTimer = window.setTimeout(
            async (changedProduct: boolean) => {
                console.debug({ changedProduct });
                const retrieveProductsPromise =
                    useResourcesStore().state.product.promise;
                if (typeof retrieveProductsPromise === "undefined") {
                    throw new Error(
                        "プログラムエラーです。初期化処理が開始されていません。プログラムのエントリーポイントで resources store の初期化を行なって下さい。"
                    );
                }
                await retrieveProductsPromise;

                const productModel = prodModel.value();

                // 商品が見つからないか未選択の場合は何もせず終了.
                if (!productModel) return;

                // お客様情報からコピーしてくる場合.
                if (state.value.loginInfo.copyFromCustomerInfo) {
                    if (productModel.inputableLoginInfoLastName) {
                        state.value.loginInfo.nameLastName =
                            state.value.customerInfo.nameLastName || "";
                    }
                    if (productModel.inputableLoginInfoFirstName) {
                        state.value.loginInfo.nameFirstName =
                            state.value.customerInfo.nameFirstName || "";
                    }

                    if (productModel.loginIdTypeIsFax) {
                        state.value.loginInfo.faxNumber =
                            state.value.customerInfo.faxNumber || "";
                    } else if (productModel.loginIdTypeIsEmail) {
                        state.value.loginInfo.email =
                            state.value.customerInfo.email || "";
                    } else if (productModel.loginIdTypeIsOptional) {
                        state.value.loginInfo.otherIdCode =
                            state.value.customerInfo.email || "";
                    }
                }

                // 商品が求める識別コードの形式と異なる識別コードの入力値を空にする.
                if (productModel.loginIdTypeIsEmail) {
                    state.value.loginInfo.faxNumber = "";
                    state.value.loginInfo.otherIdCode = "";
                } else if (productModel.loginIdTypeIsFax) {
                    state.value.loginInfo.email = "";
                    state.value.loginInfo.otherIdCode = "";
                } else if (productModel.loginIdTypeIsOptional) {
                    state.value.loginInfo.email = "";
                    state.value.loginInfo.faxNumber = "";
                }

                // お客様IDの入力が商品により禁止されている場合, 空にする.
                if (productModel.prohibitedCustomerId) {
                    state.value.loginInfo.customerId = "";
                }
                // 姓 〃
                if (productModel.prohibitedLoginInfoLastName) {
                    state.value.loginInfo.nameLastName = "";
                }
                // 名 〃
                if (productModel.prohibitedLoginInfoFirstName) {
                    state.value.loginInfo.nameFirstName = "";
                }
                // 請求内訳コメント 〃
                if (productModel.prohibitedLoginInfoComment) {
                    state.value.loginInfo.comment = "";
                }
                // 識別コード 〃
                if (productModel.loginIdTypeIsProhibited) {
                    state.value.loginInfo.faxNumber = "";
                    state.value.loginInfo.email = "";
                    state.value.loginInfo.otherIdCode = "";
                }

                // 請求内訳コメントの初期値を設定する
                if (changedProduct) {
                    state.value.loginInfo.comment =
                        productModel.loginInfoCommentDefaultValue;
                }
            },
            0,
            changedProduct
        );
    };

    const manageCheckboxRules = () => {
        const productModel = prodModel.value();
        if (!productModel) return;

        // 初期値を設定
        state.value.billingInfo.wantDownloadStatement =
            productModel.ruleDetailsDl.defaultValue;
        state.value.billingInfo.wantSplitWithStatementSplittingCode =
            productModel.ruleDetailsSplitCode.defaultValue;
    };

    const setFirstFormForceValidation = (status: boolean) => {
        state.value._meta.forceValidation.privacyInfo = status;
        state.value._meta.forceValidation.nxwSalesPerson = status;
        state.value._meta.forceValidation.applicationInfo = status;
        state.value._meta.forceValidation.customerInfo = status;
        state.value._meta.forceValidation.approverInfo = status;
    };

    const setSecondFormForceValidation = (status: boolean) => {
        state.value._meta.forceValidation.billingInfo = status;
    };

    const genFormDataBody = async (): Promise<SubmitFormRequest> => {
        await (useResourcesStore().state.product.promise || Promise.resolve());

        setFirstFormForceValidation(true);
        setSecondFormForceValidation(true);

        // NOTE: 全部入れないとここまで到達できないはずだけど, 心もとない.
        if (existFormErrors.value) {
            const msg = "入力不備が見つかりました。";
            console.error("入力不備のある箇所", {
                privacyInfoErrors: privacyInfoErrors.value(),
                nxwSalesPersonErrors: nxwSalesPersonErrors.value(),
                applicationInfoErrors: applicationInfoErrors.value(),
                customerInfoErrors: customerInfoErrors.value(),
                loginInfoErrors: loginInfoErrors.value(),
                approverInfoErrors: approverInfoErrors.value(),
                billingInfoErrors: billingInfoErrors.value(),
            });
            throw new Error(msg);
        }

        const productModel = prodModel.value();
        if (!productModel) {
            const msg =
                "予期しないエラーにより商品情報が取得できませんでした。";
            throw new Error(msg);
        }
        return genFormDataStruct(
            state.value,
            productModel,
            actualCompanyName.value
        );
    };

    const sendFormData = async () => {
        console.debug("call sendFormData");
        const params = await genFormDataBody();
        console.debug("sendFormData", { params });
        state.value._meta.sendingFormData.promise = submitForm(params);
        state.value._meta.sendingFormData.errors = [];

        state.value._meta.sendingFormData.promise
            .then((result) => {
                console.debug({ result });
            })
            .catch((e) => {
                console.debug("webform/store.ts sendFormData catch", e);
                if (isAxiosError<SubmitFormErrorResponse>(e)) {
                    console.debug(e);

                    const errMsgs: string[] = [];

                    if (e.response) {
                        const { errors, message } = e.response.data;
                        if (!!errors && Object.keys(errors).length) {
                            errMsgs.push(
                                // ...Object.keys(
                                //     errors as Record<string, string[]>
                                // )
                                //     .map((k) => {
                                //         return errors[k].map(
                                //             (emsg) => `${k}: ${emsg}`
                                //         );
                                //     })
                                //     .flat()
                                ...Object.values(
                                    errors as Record<string, string[]>
                                ).flat()
                            );
                        } else if (message) {
                            errMsgs.push(message);
                        } else {
                            errMsgs.push(e.message);
                        }
                    } else {
                        errMsgs.push(e.message);
                    }

                    state.value._meta.sendingFormData.errors = errMsgs;
                } else if (e instanceof Error) {
                    state.value._meta.sendingFormData.errors = [e.message];
                }
                throw e;
            });

        return state.value._meta.sendingFormData.promise;
    };

    return {
        state,
        $reset,

        /**
         * getters
         */
        actualCompanyName,
        prodModel,
        labels,

        /**
         * errors object getter
         */
        privacyInfoErrors,
        nxwSalesPersonErrors,
        applicationInfoErrors,
        customerInfoErrors,
        loginInfoErrors,
        approverInfoErrors,
        billingInfoErrors,

        existSomeErrors,

        /**
         * errors (boolean) getters
         */
        existPrivacyInfoErrors,
        existNxwSalesPersonErrors,
        existApplicationInfoErrors,
        existCustomerInfoErrors,
        existLoginInfoErrors,
        existApproverInfoErrors,
        existFirstFormErrors,
        existSecondFormErrors,
        existFormErrors,

        /**
         * actions
         */
        setPrivacyInfoState,
        setNxwSalesPersonState,
        setApplicationInfoState,
        setCustomerInfoState,
        setLoginInfoState,
        setApproverInfoState,
        setBillingInfoState,

        retrieveAddrsByPostalCode,

        setFirstFormForceValidation,
        setSecondFormForceValidation,

        sendFormData,
    };
});
