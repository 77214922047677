export const NOT_AVAILABLE = "該当なし";

export const companyTypes = [
    "株式会社",
    "有限会社",
    "合同会社",
    "相互会社",
    "一般社団法人",
    "公益社団法人",
    "学校法人",
    "一般財団法人",
    "公益財団法人",
    "医療法人",
    "社会福祉法人",
    "宗教法人",
    "監査法人",
    "独立行政法人",
    "国立研究開発法人",
    "ＮＰＯ法人",
    "社団法人",
    "財団法人",
    NOT_AVAILABLE,
] as const;
