<template>
    <font-awesome-icons
        icon="fa-circle-notch"
        class="fa-spin"
        :class="computedClass"
        style="--fa-animation-duration: 0.8s"
        :style="{ color }"
    />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "LodingSpinner",
    props: {
        size: {
            type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10>,
            default: 2,
        },
        color: {
            type: String,
            default: "#26BED5",
        },
    },
    computed: {
        computedClass() {
            return `fa-${this.$props.size}x`;
        },
    },
});
</script>

<style lang="scss" scoped></style>
