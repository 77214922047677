<template>
    <section class="form-section">
        <template v-if="!!title">
            <SectionHeader class="form-section-title">
                {{ title }}
            </SectionHeader>
        </template>
        <div class="container"><slot></slot></div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SectionHeader from "../Atoms/SectionHeader.vue";

export default defineComponent({
    name: "FormSection",
    components: { SectionHeader },
    props: {
        title: {
            type: String,
            required: false,
            default: "",
        },
    },
});
</script>

<style lang="scss" scoped>
.form-section {
    .form-section-title {
    }
    .container {
        margin-bottom: 50px;

        &:not(:first-child) {
            margin-top: 20px;
        }
    }
}
</style>
