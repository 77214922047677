<template>
    <div class="company-field">
        <SelectField
            :model-value="prefix"
            :options="companyTypeOptions"
            :valid="valid"
            :error="error"
            @update:model-value="handleUpdatePrefix"
        />
        &nbsp;
        <InputField
            ref="inputField"
            name="$props.name"
            :model-value="companyName"
            :placeholder="placeholder"
            :required="required"
            :valid="valid"
            :error="error"
            style="width: 11rem"
            @update:model-value="handleUpdateValue"
            @keyup="handleKeyup"
        />
        &nbsp;
        <SelectField
            :model-value="suffix"
            :options="companyTypeOptions"
            :valid="valid"
            :error="error"
            @update:model-value="handleUpdateSuffix"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { extractCompanyNames } from "../../../../libs/extract-company-names";
import InputField from "../../Atoms/InputField.vue";
import SelectField from "../../Atoms/SelectField.vue";
import { useCompanyTypes } from "./hooks/use-company-types";

// FIXME: props から値が変わると法人格の排他処理が走らない...

export default defineComponent({
    name: "CompanyField",
    components: { SelectField, InputField },
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        prefix: {
            type: String,
            default: "",
        },
        suffix: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        valid: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: undefined,
        },
    },
    emits: ["update", "keyup"],
    computed: {
        companyTypes() {
            return useCompanyTypes();
        },
        companyTypeOptions() {
            return [
                {
                    key: -1,
                    value: "",
                    label: "---",
                },
                ...this.companyTypes.map((label, i) => ({
                    key: i,
                    value: label,
                    label,
                })),
            ];
        },
        companyName() {
            return extractCompanyNames(this.$props.modelValue).name;
        },
    },
    methods: {
        emitUpdate(prefix: string, name: string, suffix: string) {
            // console.debug("emitPayload", { prefix, name, suffix });
            // const payload = `${prefix}${name}${suffix}`;
            // console.debug({ payload });
            this.$emit("update", { prefix, name, suffix });
        },
        handleUpdatePrefix(v: string) {
            const payload: [string, string, string] = [
                v,
                this.companyName,
                this.suffix,
            ];
            this.emitUpdate(...payload);
        },
        handleUpdateValue(v: string) {
            const payload: [string, string, string] = [
                this.prefix,
                v,
                this.suffix,
            ];

            this.emitUpdate(...payload);
        },
        handleKeyup(v: string) {
            this.$emit("keyup", {
                prefix: this.prefix,
                name: v,
                suffix: this.suffix,
            });
        },
        handleUpdateSuffix(v: string) {
            const payload: [string, string, string] = [
                this.prefix,
                this.companyName,
                v,
            ];
            this.emitUpdate(...payload);
        },
    },
});
</script>

<style lang="scss" scoped>
.company-field {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
</style>
