<template>
    <FormSection>
        <!-- ネクスウェイ担当営業名 -->
        <FormFieldGroup
            required
            :valid="!!nxwSalesPerson.name && !errors.name"
            :error="!!errors.name"
        >
            <template #title>
                <!-- eslint-disable vue/no-v-html -->
                <span
                    class="display-contents"
                    v-html="labels.nxwSalesPerson.name"
                ></span>
                <!-- eslint-enable vue/no-v-html -->
            </template>
            <template #content>
                <InputField
                    :model-value="nxwSalesPerson.name"
                    required
                    block
                    name="nxw_sales_person_name"
                    placeholder="例）ネクスウェイ太郎"
                    :error="!!errors.name"
                    :valid="!!nxwSalesPerson.name && !errors.name"
                    @update:model-value="handleUpdate('name', $event)"
                    @keyup="handleUpdate('name', $event)"
                />
            </template>
            <template v-if="!!errors.name" #sub-content>
                <FieldErrorText>{{ errors.name }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- ネクスウェイ担当営業のメールアドレス -->
        <FormFieldGroup
            required
            :valid="!!nxwSalesPerson.email && !errors.email"
            :error="!!errors.email"
        >
            <template #title>
                <!-- eslint-disable vue/no-v-html -->
                <span
                    class="display-contents"
                    v-html="labels.nxwSalesPerson.email"
                ></span>
                <!-- eslint-enable vue/no-v-html -->
            </template>
            <template #content>
                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    "
                >
                    <InputField
                        type="text"
                        name="nxw_sales_person_email"
                        :model-value="nxwSalesPersonEmailUser"
                        :pattern="emailPattern"
                        required
                        placeholder="例）xxxxx"
                        block
                        :error="!!errors.email"
                        :valid="!!nxwSalesPerson.email && !errors.email"
                        @update:model-value="handleUpdateEmail($event)"
                        @keyup="handleUpdateEmail($event)"
                    />
                    <span style="margin-left: 0.25rem; color: #666"
                        >@{{ nxwSalesPersonEmailDomain }}</span
                    >
                </div>
            </template>
            <template #sub-content>
                <HelpText>ネクスウェイ側担当の宛先をご入力ください。</HelpText>
                <FieldErrorText v-if="!!errors.email">{{
                    errors.email
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>
    </FormSection>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { EMAIL_USER_PATTERN_REGEXP } from "../../../../../../libs/patterns";
import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import HelpText from "../../../../../ui/Atoms/HelpText.vue";
import InputField from "../../../../../ui/Atoms/InputField.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";

export type NxwSalesPersonState = WebFormState["nxwSalesPerson"];

const NXW_SALES_EMAIL_DOMAIN = "nexway.co.jp";

export default defineComponent({
    name: "NxwSalesPersonSection",
    components: {
        FormSection,
        FormFieldGroup,
        InputField,
        FieldErrorText,
        HelpText,
    },
    setup() {
        const store = useWebFormStore();

        const nxwSalesPerson = computed(() => store.state.nxwSalesPerson);
        const setNxwSalesPersonState = store.setNxwSalesPersonState;
        const nxwSalesPersonErrors = store.nxwSalesPersonErrors;
        const labels = store.labels;

        return {
            nxwSalesPerson,
            setNxwSalesPersonState,
            nxwSalesPersonErrors,
            labels,
        };
    },
    computed: {
        errors() {
            return this.nxwSalesPersonErrors();
        },
        emailPatternRegExp() {
            return EMAIL_USER_PATTERN_REGEXP;
        },
        emailPattern() {
            return this.emailPatternRegExp.toString().replace(/^\/|\/$/g, "");
        },
        nxwSalesPersonEmailUser() {
            // TODO: 最後の @ 以降を取り除いて返す.
            const lastIndex = this.nxwSalesPerson.email?.lastIndexOf(
                `@${this.nxwSalesPersonEmailDomain}`
            );
            const emailUser = this.nxwSalesPerson.email?.substring(
                0,
                lastIndex
            );
            return emailUser;
        },
        nxwSalesPersonEmailDomain() {
            return NXW_SALES_EMAIL_DOMAIN;
        },
    },
    methods: {
        async handleUpdateEmail(value: string) {
            await this.handleUpdate(
                "email",
                `${value}@${this.nxwSalesPersonEmailDomain}`
            );
        },
        async handleUpdate(
            key: keyof NxwSalesPersonState,
            value: NxwSalesPersonState[keyof NxwSalesPersonState]
        ) {
            this.setNxwSalesPersonState({
                [key]: typeof value === "string" ? value.trim() : value,
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.display-contents {
    display: contents;
}
</style>
