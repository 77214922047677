export const ROUTE_PATHS = {
    FIRST_FORM: "/",
    SECOND_FORM: "/second",
    FIRST_CONFIRM: "/confirm1of2",
    SECOND_CONFIRM: "/confirm2of2",
    FINISHED: "/finished",

    // aliases
    "/service/:productAlias": "/service/:productAlias",
} as const;
