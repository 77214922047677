import { WebFormState } from "../interfaces";

export const genInitialState = (): WebFormState => ({
    _meta: {
        forceValidation: {
            privacyInfo: false,
            nxwSalesPerson: false,
            applicationInfo: false,
            customerInfo: false,
            loginInfo: false,
            approverInfo: false,
            billingInfo: false,
        },
        sendingFormData: {
            promise: undefined,
            errors: [],
        },
    },
    privacyInfo: {
        agree: undefined,
    },
    nxwSalesPerson: {
        name: undefined,
        email: undefined,
    },
    applicationInfo: {
        registrationType: "新規",
        product: undefined,
    },
    customerInfo: {
        companyName: undefined,
        companyNameKana: undefined,
        // houjinBangou: undefined,
        department: undefined,
        position: undefined,
        nameLastName: undefined,
        nameFirstName: undefined,
        nameKanaLastName: undefined,
        nameKanaFirstName: undefined,
        phoneNumber: undefined,
        faxNumber: undefined,
        email: undefined,
        postalCode: undefined,
        addrPref: undefined,
        addrCity: undefined,
        addrStreet: undefined,
        addrOther: undefined,
    },
    loginInfo: {
        copyFromCustomerInfo: true,
        customerId: "",
        nameLastName: "",
        nameFirstName: "",
        faxNumber: "",
        email: "",
        otherIdCode: "",
        comment: "",
    },
    approverInfo: {
        // 部署・氏名
        deptOrName: undefined,
        // メールアドレス
        email: undefined,
    },
    billingInfo: {
        copyCustomerInfo: true,
        companyName: undefined,
        companyNameKana: undefined,
        department: undefined,
        nameLastName: undefined,
        nameFirstName: undefined,
        phoneNumber: undefined,
        postalCode: undefined,
        addrPref: undefined,
        addrCity: undefined,
        addrStreet: undefined,
        addrOther: undefined,
        email: undefined,
        // deptOrPlanNameOrEtc: undefined,
        billingMethod: undefined,

        billingSendTo: undefined,

        otherEmail: undefined,

        paymentMethod: undefined,

        // 利用明細（無料）のダウンロード（郵送の場合は同封）を希望
        wantDownloadStatement: undefined,
        // 明細分割コード毎に分割（１枚ずつ作成）する
        wantSplitWithStatementSplittingCode: undefined,

        // 備考
        // userNote: "",
    },
});
