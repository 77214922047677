<template>
    <CenteredLayout>
        <form class="second-form" @submit.prevent="handleSubmit">
            <p>
                ※ブラウザの「戻る」「進む」ボタンはご使用にならないようお願いいたします。
            </p>
            <p>
                以下のフォームに必要な情報をご入力の上「次へ」を押してください。
            </p>

            <FormSection>
                <FormFieldGroup>
                    <template #title>お申し込みサービス</template>
                    <template #content>{{ productModel?.name }}</template>
                </FormFieldGroup>
            </FormSection>

            <FormSection title="請求先情報">
                <div>
                    <Checkbox
                        name="billing_info_copy_customer_info"
                        :model-value="billingInfoState.copyCustomerInfo"
                        :label="labels.billingInfo.copyCustomerInfo"
                        @update:model-value="
                            handleUpdate('copyCustomerInfo', $event)
                        "
                    />
                </div>

                <!-- 会社名 -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="
                        !!billingInfoState.companyName && !errors.companyName
                    "
                    :error="!!errors.companyName"
                >
                    <template #title>{{
                        labels.billingInfo.companyName
                    }}</template>
                    <template #content>
                        <InputField
                            block
                            name="billing_info_company_name"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :model-value="billingInfoState.companyName"
                            :valid="
                                !!billingInfoState.companyName &&
                                !errors.companyName
                            "
                            :error="!!errors.companyName"
                            @update:model-value="
                                handleUpdate('companyName', $event)
                            "
                            @keyup="handleUpdate('companyName', $event)"
                        />
                    </template>
                    <template v-if="!!errors.companyName" #sub-content>
                        <FieldErrorText>{{
                            errors.companyName
                        }}</FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 会社名フリガナ -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="
                        !!billingInfoState.companyNameKana &&
                        !errors.companyNameKana
                    "
                    :error="!!errors.companyNameKana"
                >
                    <template #title>{{
                        labels.billingInfo.companyNameKana
                    }}</template>
                    <template #content>
                        <InputField
                            block
                            name="billing_info_company_name_kana"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :model-value="billingInfoState.companyNameKana"
                            :valid="
                                !!billingInfoState.companyNameKana &&
                                !errors.companyNameKana
                            "
                            :error="!!errors.companyNameKana"
                            @update:model-value="
                                handleUpdate('companyNameKana', $event)
                            "
                            @keyup="handleUpdate('companyNameKana', $event)"
                        />
                    </template>
                    <template #sub-content>
                        <HelpText
                            >全角カナ、全角スペース、全角ハイフン、半角ハイフンのみ</HelpText
                        >
                        <FieldErrorText v-if="!!errors.companyNameKana">
                            {{ errors.companyNameKana }}
                        </FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 部署名 -->
                <FormFieldGroup
                    :valid="!!billingInfoState.department && !errors.department"
                    :error="!!errors.department"
                >
                    <template #title>{{
                        labels.billingInfo.department
                    }}</template>
                    <template #content>
                        <InputField
                            block
                            name="billing_info_department"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :model-value="billingInfoState.department"
                            :valid="
                                !!billingInfoState.department &&
                                !errors.department
                            "
                            :error="!!errors.department"
                            @update:model-value="
                                handleUpdate('department', $event)
                            "
                            @keyup="handleUpdate('department', $event)"
                        />
                    </template>
                    <template v-if="!!errors.department" #sub-content>
                        <FieldErrorText>
                            {{ errors.department }}
                        </FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- お名前 -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="
                        !!billingInfoState.nameLastName &&
                        !errors.nameLastName &&
                        !!billingInfoState.nameFirstName &&
                        !errors.nameFirstName
                    "
                    :error="!!errors.nameLastName || !!errors.nameFirstName"
                >
                    <template #title>{{ labels.billingInfo.name }}</template>
                    <template #content>
                        <div class="flex-row-align-centered-content-start">
                            <!-- 姓 -->
                            <div class="flex-row-align-centered-content-start">
                                <!-- FIXME: span どどっちがいいんだ？ -->
                                <label for="last_name"
                                    >{{
                                        labels.billingInfo.nameLastName
                                    }}:&nbsp;</label
                                >
                                <InputField
                                    id="last_name"
                                    name="billing_info_name_last_name"
                                    style="width: 10rem"
                                    :readonly="
                                        billingInfoState.copyCustomerInfo
                                    "
                                    :model-value="billingInfoState.nameLastName"
                                    :valid="
                                        !!billingInfoState.nameLastName &&
                                        !errors.nameLastName
                                    "
                                    :error="!!errors.nameLastName"
                                    @update:model-value="
                                        handleUpdate('nameLastName', $event)
                                    "
                                    @keyup="
                                        handleUpdate('nameLastName', $event)
                                    "
                                />
                            </div>
                            <!-- 名 -->
                            <div class="flex-row-align-centered-content-start">
                                <label for="first_name"
                                    >{{
                                        labels.billingInfo.nameFirstName
                                    }}:&nbsp;</label
                                >
                                <InputField
                                    id="first_name"
                                    name="billing_info_name_first_name"
                                    style="width: 10rem"
                                    :readonly="
                                        billingInfoState.copyCustomerInfo
                                    "
                                    :model-value="
                                        billingInfoState.nameFirstName
                                    "
                                    :valid="
                                        !!billingInfoState.nameFirstName &&
                                        !errors.nameFirstName
                                    "
                                    :error="!!errors.nameFirstName"
                                    @update:model-value="
                                        handleUpdate('nameFirstName', $event)
                                    "
                                    @keyup="
                                        handleUpdate('nameFirstName', $event)
                                    "
                                />
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="!!errors.nameLastName || !!errors.nameFirstName"
                        #sub-content
                    >
                        <FieldErrorText v-if="!!errors.nameLastName">
                            {{ errors.nameLastName }}
                        </FieldErrorText>
                        <FieldErrorText v-if="!!errors.nameFirstName">
                            {{ errors.nameFirstName }}
                        </FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 電話番号 -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="
                        !!billingInfoState.phoneNumber && !errors.phoneNumber
                    "
                    :error="!!errors.phoneNumber"
                >
                    <template #title>{{
                        labels.billingInfo.phoneNumber
                    }}</template>
                    <template #content>
                        <InputField
                            block
                            name="billing_info_phone_number"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :model-value="billingInfoState.phoneNumber"
                            :valid="
                                !!billingInfoState.phoneNumber &&
                                !errors.phoneNumber
                            "
                            :error="!!errors.phoneNumber"
                            :maxlength="11"
                            @update:model-value="
                                handleUpdate('phoneNumber', $event)
                            "
                            @keyup="handleUpdate('phoneNumber', $event)"
                        />
                    </template>
                    <template #sub-content>
                        <HelpText>半角数字のみ、ハイフンなし</HelpText>
                        <HelpText>登記上の電話番号を記載ください</HelpText>
                        <FieldErrorText v-if="!!errors.phoneNumber">
                            {{ errors.phoneNumber }}
                        </FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 郵便番号 -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="!!billingInfoState.postalCode && !errors.postalCode"
                    :error="!!errors.postalCode"
                >
                    <template #title>{{
                        labels.billingInfo.postalCode
                    }}</template>
                    <template #content>
                        <InputField
                            block
                            name="billing_info_postal_code"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :model-value="billingInfoState.postalCode"
                            :valid="
                                !!billingInfoState.postalCode &&
                                !errors.postalCode
                            "
                            :error="!!errors.postalCode"
                            @update:model-value="
                                handleUpdate('postalCode', $event)
                            "
                            @keyup="handleUpdate('postalCode', $event)"
                        />
                    </template>
                    <template #sub-content>
                        <HelpText>半角数字のみ、ハイフンなし</HelpText>
                        <FieldErrorText v-if="!!errors.postalCode">
                            {{ errors.postalCode }}
                        </FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 住所 -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="
                        !!billingInfoState.addrPref &&
                        !errors.addrPref &&
                        !!billingInfoState.addrCity &&
                        !errors.addrCity &&
                        !!billingInfoState.addrStreet &&
                        !errors.addrStreet &&
                        !errors.addrOther
                    "
                    :error="
                        !!errors.addrPref ||
                        !!errors.addrCity ||
                        !!errors.addrStreet ||
                        !!errors.addrOther
                    "
                >
                    <template #title>{{ labels.billingInfo.address }}</template>
                    <template #content>
                        <AddrFields
                            name-prefix="billing_info_addr"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :pref="{
                                value: billingInfoState.addrPref,
                                valid:
                                    !!billingInfoState.addrPref &&
                                    !errors.addrPref,
                                error: !!errors.addrPref,
                                errorMessage: errors.addrPref,
                            }"
                            :city="{
                                value: billingInfoState.addrCity,
                                valid:
                                    !!billingInfoState.addrCity &&
                                    !errors.addrCity,
                                error: !!errors.addrCity,
                                errorMessage: errors.addrCity,
                            }"
                            :street="{
                                value: billingInfoState.addrStreet,
                                valid:
                                    !!billingInfoState.addrStreet &&
                                    !errors.addrStreet,
                                error: !!errors.addrStreet,
                                errorMessage: errors.addrStreet,
                            }"
                            :other="{
                                value: billingInfoState.addrOther,
                                valid:
                                    !!billingInfoState.addrOther &&
                                    !errors.addrOther,
                                error: !!errors.addrOther,
                                errorMessage: errors.addrOther,
                            }"
                            :pref-label="labels.billingInfo.addrPref"
                            :city-label="labels.billingInfo.addrCity"
                            :street-label="labels.billingInfo.addrStreet"
                            :other-label="labels.billingInfo.addrOther"
                            @update:pref="handleUpdate('addrPref', $event)"
                            @update:city="handleUpdate('addrCity', $event)"
                            @keyup:city="handleUpdate('addrCity', $event)"
                            @update:street="handleUpdate('addrStreet', $event)"
                            @keyup:street="handleUpdate('addrStreet', $event)"
                            @update:other="handleUpdate('addrOther', $event)"
                            @keyup:other="handleUpdate('addrOther', $event)"
                        />
                    </template>
                    <template #sub-content>
                        <HelpText>登記上の住所を記載ください</HelpText>
                    </template>
                </FormFieldGroup>

                <!-- メールアドレス -->
                <FormFieldGroup
                    :required="!billingInfoState.copyCustomerInfo"
                    :valid="!!billingInfoState.email && !errors.email"
                    :error="!!errors.email"
                >
                    <template #title>{{ labels.billingInfo.email }}</template>
                    <template #content>
                        <InputField
                            block
                            type="email"
                            name="billing_info_email"
                            :readonly="billingInfoState.copyCustomerInfo"
                            :model-value="billingInfoState.email"
                            placeholder="例）xxxx@example.com"
                            :valid="!!billingInfoState.email && !errors.email"
                            :error="!!errors.email"
                            @update:model-value="handleUpdate('email', $event)"
                            @keyup="handleUpdate('email', $event)"
                        />
                    </template>
                    <template #sub-content>
                        <HelpText>半角英数記号のみ</HelpText>
                        <FieldErrorText v-if="!!errors.email">
                            {{ errors.email }}
                        </FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 請求方法 -->
                <FormFieldGroup
                    required
                    :valid="
                        !!billingInfoState.billingMethod &&
                        !errors.billingMethod
                    "
                    :error="!!errors.billingMethod"
                >
                    <template #title>{{
                        labels.billingInfo.billingMethod
                    }}</template>
                    <template #content>
                        <RadioButtons
                            name="billing_info_billing_method"
                            required
                            :model-value="billingInfoState.billingMethod"
                            :items="[
                                {
                                    value: 'Web請求',
                                    label: labels.billingInfo
                                        .billingMethodByWeb,
                                },
                                {
                                    value: '郵送',
                                    label: labels.billingInfo
                                        .billingMethodBySnailMail,
                                },
                            ]"
                            @update:model-value="
                                handleUpdate('billingMethod', $event)
                            "
                            @keyup="handleUpdate('billingMethod', $event)"
                        />
                    </template>
                    <template #sub-content>
                        <HelpText v-if="billingInfoState.billingMethod === '郵送'">郵送の場合は下記のURLをご覧ください。</HelpText>
                        <HelpText v-if="billingInfoState.billingMethod === '郵送'"><a href="https://nxw.my.salesforce.com/sfc/p/#100000004sAB/a/IR0000005oiz/VB1Cv9S.dr.KzODdkw669FfD072jjMfVqoIkVX0JaHk" target="_blank">請求書郵送有料化について</a></HelpText>
                        <FieldErrorText v-if="!!errors.billingMethod">{{
                            errors.billingMethod
                        }}</FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 請求宛先 -->
                <transition name="fade">
                    <FormFieldGroup
                        v-if="billingInfoState.billingMethod === 'Web請求'"
                        required
                        :valid="
                            (billingInfoState.billingSendTo ===
                                '請求先のe-mailアドレス宛' ||
                                billingInfoState.billingSendTo === 'その他') &&
                            !errors.billingSendTo
                        "
                        :error="!!errors.billingSendTo"
                    >
                        <template #title>請求宛先</template>
                        <template #content>
                            <RadioButtons
                                name="billing_info_billing_send_to"
                                required
                                :model-value="billingInfoState.billingSendTo"
                                :items="[
                                    {
                                        value: '請求先のe-mailアドレス宛',
                                        label: labels.billingInfo
                                            .billingSendToBillingEmail,
                                    },
                                    {
                                        value: 'その他',
                                        label: labels.billingInfo
                                            .billingSendToOther,
                                    },
                                ]"
                                @update:model-value="
                                    handleUpdate('billingSendTo', $event)
                                "
                                @keyup="handleUpdate('billingSendTo', $event)"
                            />
                        </template>
                        <template v-if="!!errors.billingSendTo" #sub-content>
                            <FieldErrorText>{{
                                errors.billingSendTo
                            }}</FieldErrorText>
                        </template>
                    </FormFieldGroup>
                </transition>

                <transition name="fade">
                    <!-- その他メールアドレス -->
                    <FormFieldGroup
                        v-if="billingInfoState.billingSendTo === 'その他'"
                        required
                        :valid="
                            !!billingInfoState.otherEmail && !invalidOtherEmails
                        "
                        :error="invalidOtherEmails"
                    >
                        <template #title>
                            <!-- eslint-disable vue/no-v-html -->
                            <span
                                class="display-contents"
                                v-html="labels.billingInfo.otherEmail"
                            >
                            </span>
                            <!-- eslint-enable vue/no-v-html -->
                        </template>
                        <template #content>
                            <ol class="billing_info_other_emails_list">
                                <li>
                                    <InputField
                                        block
                                        name="billing_info_other_email"
                                        type="email"
                                        required
                                        :model-value="
                                            billingInfoState.otherEmail
                                        "
                                        :valid="
                                            !errors.otherEmail &&
                                            !!billingInfoState.otherEmail
                                        "
                                        :error="!!errors.otherEmail"
                                        placeholder="xxxx@nexway.co.jp"
                                        @update:model-value="
                                            handleUpdate('otherEmail', $event)
                                        "
                                        @keyup="
                                            handleUpdate('otherEmail', $event)
                                        "
                                    />
                                </li>
                                <li>
                                    <InputField
                                        block
                                        name="billing_info_other_email2"
                                        type="email"
                                        :model-value="
                                            billingInfoState.otherEmail2
                                        "
                                        :valid="
                                            !errors.otherEmail2 &&
                                            !!billingInfoState.otherEmail2
                                        "
                                        :error="!!errors.otherEmail2"
                                        placeholder="xxxx@nexway.co.jp"
                                        @update:model-value="
                                            handleUpdate('otherEmail2', $event)
                                        "
                                        @keyup="
                                            handleUpdate('otherEmail2', $event)
                                        "
                                    />
                                </li>
                                <li>
                                    <InputField
                                        block
                                        name="billing_info_other_email3"
                                        type="email"
                                        :model-value="
                                            billingInfoState.otherEmail3
                                        "
                                        :valid="
                                            !errors.otherEmail3 &&
                                            !!billingInfoState.otherEmail3
                                        "
                                        :error="!!errors.otherEmail3"
                                        placeholder="xxxx@nexway.co.jp"
                                        @update:model-value="
                                            handleUpdate('otherEmail3', $event)
                                        "
                                        @keyup="
                                            handleUpdate('otherEmail3', $event)
                                        "
                                    />
                                </li>
                                <li>
                                    <InputField
                                        block
                                        name="billing_info_other_email4"
                                        type="email"
                                        :model-value="
                                            billingInfoState.otherEmail4
                                        "
                                        :valid="
                                            !errors.otherEmail4 &&
                                            !!billingInfoState.otherEmail4
                                        "
                                        :error="!!errors.otherEmail4"
                                        placeholder="xxxx@nexway.co.jp"
                                        @update:model-value="
                                            handleUpdate('otherEmail4', $event)
                                        "
                                        @keyup="
                                            handleUpdate('otherEmail4', $event)
                                        "
                                    />
                                </li>
                                <li>
                                    <InputField
                                        block
                                        name="billing_info_other_email5"
                                        type="email"
                                        :model-value="
                                            billingInfoState.otherEmail5
                                        "
                                        :valid="
                                            !errors.otherEmail5 &&
                                            !!billingInfoState.otherEmail5
                                        "
                                        :error="!!errors.otherEmail5"
                                        placeholder="xxxx@nexway.co.jp"
                                        @update:model-value="
                                            handleUpdate('otherEmail5', $event)
                                        "
                                        @keyup="
                                            handleUpdate('otherEmail5', $event)
                                        "
                                    />
                                </li>
                            </ol>
                        </template>
                        <template #sub-content>
                            <HelpText>半角英数記号のみ</HelpText>
                            <template v-if="invalidOtherEmails">
                                <FieldErrorText
                                    v-for="(msg, i) in otherEmailsErrors"
                                    :key="i"
                                >
                                    {{ msg }}
                                </FieldErrorText>
                            </template>
                        </template>
                    </FormFieldGroup></transition
                >

                <!-- 支払方法 -->
                <FormFieldGroup
                    required
                    :valid="
                        !!billingInfoState.paymentMethod &&
                        !errors.paymentMethod
                    "
                    :error="!!errors.paymentMethod"
                >
                    <template #title>{{
                        labels.billingInfo.paymentMethod
                    }}</template>
                    <template #content>
                        <RadioButtons
                            name="billing_info_payment_method"
                            required
                            :model-value="billingInfoState.paymentMethod"
                            :items="[
                                {
                                    value: '銀行引き落とし',
                                    label: labels.billingInfo
                                        .paymentMethodByBankDebit,
                                },
                                {
                                    value: '振込',
                                    label: labels.billingInfo
                                        .paymentMethodByTransferMoney,
                                },
                            ]"
                            @update:model-value="
                                handleUpdate('paymentMethod', $event)
                            "
                        />
                    </template>
                    <template v-if="!!errors.paymentMethod" #sub-content>
                        <FieldErrorText>{{
                            errors.paymentMethod
                        }}</FieldErrorText>
                    </template>
                </FormFieldGroup>

                <!-- 利用明細、請求内訳等 -->
                <FormFieldGroup
                    v-if="
                        editableSomeCheckboxRules ||
                        defaultCheckedSomeCheckboxRules
                    "
                >
                    <template #title>{{
                        labels.billingInfo.wantOptions
                    }}</template>
                    <template #content>
                        <!-- 利用明細（無料）のダウンロード（郵送の場合は同封）を希望 -->
                        <Checkbox
                            name="billing_info_want_download_statement"
                            :label="labels.billingInfo.wantDownloadStatement"
                            :read-only="!productModel?.ruleDetailsDl.editable"
                            :model-value="
                                billingInfoState.wantDownloadStatement
                            "
                            @update:model-value="
                                handleUpdate('wantDownloadStatement', $event)
                            "
                        />
                        <!-- 明細分割コード毎に分割（１枚ずつ作成）する -->
                        <Checkbox
                            name="billing_info_want_split_with_statement_splitting_code"
                            :label="
                                labels.billingInfo
                                    .wantSplitWithStatementSplittingCode
                            "
                            :read-only="
                                !productModel?.ruleDetailsSplitCode.editable
                            "
                            :model-value="
                                billingInfoState.wantSplitWithStatementSplittingCode
                            "
                            @update:model-value="
                                handleUpdate(
                                    'wantSplitWithStatementSplittingCode',
                                    $event
                                )
                            "
                        />
                    </template>
                </FormFieldGroup>
            </FormSection>

            <div class="submit-button-container">
                <BackButton @click="handleGoToBack" />
                <NextButton type="submit" />
            </div>
        </form>
    </CenteredLayout>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useResourcesStore } from "../../../../../../store/resources";
import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import BackButton from "../../../../../ui/Atoms/BackButton.vue";
import Checkbox from "../../../../../ui/Atoms/Checkbox.vue";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import HelpText from "../../../../../ui/Atoms/HelpText.vue";
import InputField from "../../../../../ui/Atoms/InputField.vue";
import NextButton from "../../../../../ui/Atoms/NextButton.vue";
import RadioButtons from "../../../../../ui/Atoms/RadioButtons.vue";
import AddrFields from "../../../../../ui/Molecures/AddrFields.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";
import CenteredLayout from "../../../../../ui/Templates/CenteredLayout.vue";

type BillingAddrState = WebFormState["billingInfo"];

export default defineComponent({
    name: "SecondForm",
    components: {
        CenteredLayout,
        FormSection,
        FormFieldGroup,
        BackButton,
        NextButton,
        Checkbox,
        RadioButtons,
        InputField,
        AddrFields,
        FieldErrorText,
        HelpText,
    },
    emits: ["submit", "goToBack"],
    setup() {
        const webformStore = useWebFormStore();
        const resourcesStore = useResourcesStore();

        const billingInfoState = computed(() => webformStore.state.billingInfo);

        const getProductById = resourcesStore.getProductById;
        const productId = computed(
            () => webformStore.state.applicationInfo.product
        );

        const billingInfoErrors = webformStore.billingInfoErrors;
        const setBillingInfoState = webformStore.setBillingInfoState;
        const prodModel = webformStore.prodModel;

        const labels = webformStore.labels;

        return {
            productId,
            getProductById,
            billingInfoState,
            setBillingInfoState,
            billingInfoErrors,
            prodModel,
            labels,
        };
    },
    computed: {
        errors() {
            return this.billingInfoErrors();
        },
        otherEmailsErrors() {
            return Array.from(
                new Set([
                    this.errors.otherEmail,
                    this.errors.otherEmail2,
                    this.errors.otherEmail3,
                    this.errors.otherEmail4,
                    this.errors.otherEmail5,
                ])
            );
        },
        invalidOtherEmails() {
            return this.otherEmailsErrors.some(Boolean);
        },
        prodName() {
            return this.productId
                ? this.getProductById(this.productId)?.name
                : "undefined";
        },
        productModel() {
            return this.prodModel();
        },
        editableSomeCheckboxRules() {
            return (
                this.productModel?.ruleDetailsDl.editable ||
                this.productModel?.ruleDetailsSplitCode.editable
            );
        },
        defaultCheckedSomeCheckboxRules() {
            return (
                this.productModel?.ruleDetailsDl.defaultValue ||
                this.productModel?.ruleDetailsSplitCode.defaultValue
            );
        },
    },
    methods: {
        handleUpdate(
            key: keyof BillingAddrState,
            value: BillingAddrState[keyof BillingAddrState]
        ) {
            this.setBillingInfoState({ [key]: value });
        },
        handleSubmit() {
            this.$emit("submit");
        },
        handleGoToBack() {
            this.$emit("goToBack");
        },
    },
});
</script>

<style lang="scss" scoped>
.second-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    .form-description {
        font-size: 0.875em;
        margin: 0 0 2.25rem;
    }

    @media screen and (max-width: 768px) {
        width: auto;
    }

    .submit-button-container {
        margin: 3rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        & > * {
            margin-bottom: 1rem;
        }
    }

    .flex-row-align-centered-content-start {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 1rem;
        flex-wrap: wrap;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

$fade-animation-duration: 60ms;

.fade-enter-active {
    animation: fadeIn $fade-animation-duration;
}

.fade-leave-active {
    animation: fadeOut $fade-animation-duration;
}

@keyframes fadeIn {
    0% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        max-height: max-content;
        opacity: 1;
    }
    100% {
        max-height: 0;
        opacity: 0.4;
    }
}

.display-contents {
    display: contents;
}

.billing_info_other_emails_list {
    padding-left: 1.5rem;
    margin: 0;
    box-sizing: border-box;
    & > li {
        margin-bottom: 0.5rem;
    }
}
</style>
