<template>
    <button
        class="next-button"
        :type="type"
        :disabled="disabled"
        @click="$emit('click', $event)"
        @submit="$emit('submit', $event)"
    >
        次へ
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "NextButton",
    props: {
        type: {
            type: String as PropType<"button" | "submit" | "reset">,
            default: "button",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["click", "submit"],
});
</script>

<style lang="scss" scoped>
.next-button {
    box-sizing: border-box;
    width: 200px;
    height: 60px;
    padding: 0px;
    margin: 0px;
    border: none;
    background: url(/images/next-btn.gif) left top no-repeat;

    color: transparent;
    cursor: pointer;

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background-image: url(/images/next-btn-on.gif);
    }
}
</style>
