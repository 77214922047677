<template>
    <label
        class="checkbox-container"
        :class="{ disabled, 'read-only': readOnly }"
    >
        <template v-if="readOnly">
            <input
                type="hidden"
                :name="name"
                :checked="modelValue"
                :disabled="disabled"
            />
            <input
                type="checkbox"
                :name="`${name}_readonly`"
                :checked="modelValue"
                @click.prevent="() => false"
            />
        </template>
        <template v-else>
            <input
                type="checkbox"
                :name="name"
                :checked="modelValue"
                :required="required"
                :disabled="disabled"
                @change="handleUpdate"
            />
        </template>
        &nbsp;
        <slot
            ><span class="label-text">{{ label }}</span></slot
        >
    </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "LabeledCheckbox",
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: undefined,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
    methods: {
        handleUpdate($event: Event) {
            this.$emit(
                "update:modelValue",
                ($event.currentTarget as HTMLInputElement).checked
            );
        },
    },
});
</script>

<style lang="scss" scoped>
.checkbox-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 0.425rem 0.25rem;
    box-sizing: content-box;

    &:hover {
        background-color: rgba(0, 0, 0, 0.025);
        border-radius: 5px;
    }

    .label-text {
        font-size: 0.9rem;
    }

    &.read-only {
        // & > input[type="checkbox"] {
        opacity: 0.6;
        // }
    }
}
</style>
