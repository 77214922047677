<template>
    <div class="addr-fields">
        <span class="field-label">{{ prefLabel }}:&nbsp;</span>
        <div class="field-set">
            <SelectField
                :model-value="pref?.value"
                :disabled="disabled"
                required
                :name="prefName"
                :options="prefsOptionList"
                :valid="pref?.valid"
                :error="pref?.error"
                :readonly="readonly"
                @update:model-value="$emit('update:pref', $event)"
            />
            <FieldErrorText v-if="!!pref?.errorMessage">
                {{ pref?.errorMessage }}
            </FieldErrorText>
        </div>
        <span class="field-label">{{ cityLabel }}:&nbsp;</span>
        <div class="field-set">
            <InputField
                :name="cityName"
                :model-value="city?.value"
                :readonly="readonly"
                :disabled="disabled"
                required
                placeholder="例）港区"
                :valid="city?.valid"
                :error="city?.error"
                @update:model-value="$emit('update:city', $event)"
                @keyup="$emit('keyup:city', $event)"
            />
            <FieldErrorText v-if="!!city?.errorMessage">
                {{ city?.errorMessage }}
            </FieldErrorText>
        </div>
        <span class="field-label">{{ streetLabel }}:&nbsp;</span>
        <div class="field-set">
            <InputField
                :name="streetName"
                :model-value="street?.value"
                required
                :readonly="readonly"
                :disabled="disabled"
                :valid="street?.valid"
                :error="street?.error"
                placeholder="例）1-1-1"
                @update:model-value="$emit('update:street', $event)"
                @keyup="$emit('keyup:street', $event)"
            />
            <FieldErrorText v-if="!!street?.errorMessage">
                {{ street?.errorMessage }}
            </FieldErrorText>
        </div>
        <span class="field-label">{{ otherLabel }}:&nbsp;</span>
        <div class="field-set">
            <InputField
                :name="otherName"
                :model-value="other?.value"
                placeholder="例）○○ビルディング"
                :readonly="readonly"
                :disabled="disabled"
                :valid="other?.valid"
                :error="other?.error"
                @update:model-value="$emit('update:other', $event)"
                @keyup="$emit('keyup:other', $event)"
            />
            <FieldErrorText v-if="!!other?.errorMessage">
                {{ other?.errorMessage }}
            </FieldErrorText>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { usePrefs } from "../../../hooks/usePrefs";
import FieldErrorText from "../Atoms/FieldErrorText.vue";
import InputField from "../Atoms/InputField.vue";
import SelectField, { Option } from "../Atoms/SelectField.vue";

type FieldDef = {
    value?: string;
    valid?: boolean;
    error?: boolean;
    errorMessage?: string;
};

export default defineComponent({
    name: "AddrFields",
    components: { SelectField, FieldErrorText, InputField },
    props: {
        namePrefix: {
            type: String,
            default: "",
        },
        pref: {
            type: Object as PropType<FieldDef>,
            default: undefined,
        },
        prefLabel: {
            type: String,
            default: "都道府県",
        },
        city: {
            type: Object as PropType<FieldDef>,
            default: undefined,
        },
        cityLabel: {
            type: String,
            default: "市区郡",
        },
        street: {
            type: Object as PropType<FieldDef>,
            default: undefined,
        },
        streetLabel: {
            type: String,
            default: "町名番地",
        },
        other: {
            type: Object as PropType<FieldDef>,
            default: undefined,
        },
        otherLabel: {
            type: String,
            default: "建物・その他",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "update:pref",
        "update:city",
        "keyup:city",
        "update:street",
        "keyup:street",
        "update:other",
        "keyup:other",
    ],
    computed: {
        prefs() {
            return usePrefs();
        },
        prefsOptionList(): Option[] {
            return [
                {
                    key: -1,
                    value: "",
                    label: "選択してください",
                },
                ...this.prefs.map((p) => ({
                    key: p.code,
                    value: p.name,
                    label: p.name,
                })),
            ];
        },

        prefName() {
            return [this.namePrefix, "pref"].filter(Boolean).join("_");
        },
        cityName() {
            return [this.namePrefix, "city"].filter(Boolean).join("_");
        },
        streetName() {
            return [this.namePrefix, "street"].filter(Boolean).join("_");
        },
        otherName() {
            return [this.namePrefix, "other"].filter(Boolean).join("_");
        },
    },
});
</script>

<style lang="scss" scoped>
.addr-fields {
    display: grid;
    grid-column-end: 2 2;
    grid-template-columns: auto 1fr;
    // align-items: center;
    align-items: baseline;
    row-gap: 0.5rem;

    > .field-label {
        text-align: right;
        font-size: 0.9rem;
    }
    > .field-set {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }
}
</style>
