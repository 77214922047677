<template>
    <template v-if="awaiting">
        <slot name="awaiting"
            ><div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                "
            >
                <LoadingSpinner />
            </div>
        </slot>
    </template>
    <template v-else-if="resolved">
        <slot name="resolved"><div>resolved</div></slot>
    </template>
    <template v-else-if="rejected">
        <slot name="rejected"><div>rejected</div></slot>
    </template>
    <template v-else-if="somethingWentWrong">
        <slot name="somethingWentWrong"><div>something went wrong!</div></slot>
    </template>
    <template v-else>
        <slot name="never"><div>never</div></slot>
    </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import LoadingSpinner from "../ui/Atoms/LoadingSpinner.vue";

type Data = {
    awaitNow: boolean;
    caughtError: boolean;
};

const genInitData = (): Data => ({
    awaitNow: true,
    caughtError: false,
});

export default defineComponent({
    name: "DoAwaiting",
    components: { LoadingSpinner },
    props: {
        for: {
            type: Object as PropType<Promise<unknown> | undefined>,
            required: false,
            default: undefined,
        },
    },
    emits: ["resolved", "rejected", "completed"],
    data(): Data {
        return genInitData();
    },
    computed: {
        resolved() {
            return !this.awaitNow && !this.caughtError;
        },
        rejected() {
            return !this.awaitNow && this.caughtError;
        },
        awaiting() {
            return this.awaitNow && !this.caughtError;
        },
        somethingWentWrong() {
            return this.awaitNow && this.caughtError;
        },
    },
    watch: {
        for: {
            handler() {
                this.describePromies();
            },
        },
    },
    beforeMount() {
        this.describePromies();
    },
    methods: {
        describePromies() {
            const { awaitNow, caughtError } = genInitData();
            this.$data.awaitNow = awaitNow;
            this.$data.caughtError = caughtError;

            if (typeof this.$props.for !== "undefined") {
                this.$props.for &&
                    this.$props.for
                        .then(this.resolvePromise)
                        .catch(this.catchPromise)
                        .finally(this.finallyPromise);
            } else {
                this.resolvePromise();
            }
        },
        resolvePromise() {
            this.awaitNow = false;
            this.$emit("resolved");
        },
        catchPromise() {
            this.awaitNow = false;
            this.caughtError = true;
            this.$emit("rejected");
        },
        finallyPromise() {
            this.$emit("completed");
        },
    },
});
</script>

<style lang="scss" scoped></style>
