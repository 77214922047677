import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useScrollStore = defineStore("useScrollStore", () => {
    const state = ref({ top: 0 });

    const $reset = () => (state.value.top = 0);

    const setScrollTop = (top: number) => {
        state.value.top = top;
    };

    const scrollTop = computed(() => state.value.top);

    return {
        setScrollTop,
        scrollTop,
        $reset,
    };
});
