import axios from "axios";

import {
    Product,
    SubmitFormErrorResponse,
    SubmitFormRequest,
} from "./interfaces";

const baseURL = import.meta.env.VITE_APP_API_ROOT;
const http = axios.create({
    baseURL,
    timeout: 30000,
});
http.interceptors.response.use(
    (res) => res,
    (err) => {
        if (typeof err.response === "undefined") {
            alert(
                "ネットワークエラーが発生しました。インターネット接続が途切れたか、CORSの問題が発生しました。"
            );
        }
        return Promise.reject(err);
    }
);

export const retrieveProducts = async () => http.get<Product[]>("/products");

// type HoujinNumberApiResponse = {
//     name: string;
//     name_kana: string;
//     postal_code: string;
//     addr_pref: string;
//     addr_city: string;
//     addr_street_other: string;
// };

// export const retrieveCorpInfoByHoujinBangou = async (num: string) =>
//     request<HoujinNumberApiResponse>("GET", `/houjin-bangou?number=${num}`);

type PostalCodeApiResponse = {
    pref: string;
    city: string;
    street: string;
    other_addr?: string;
};
export const retrieveAddrByPostalCode = async (code1: string, code2: string) =>
    http.get<PostalCodeApiResponse>(
        `/postalcode?code1=${code1.trim()}&code2=${code2.trim()}`
    );

export const submitForm = async (params: SubmitFormRequest) =>
    http.post<null, SubmitFormErrorResponse>("/submit", params);

export default { retrieveProducts, submitForm };
