<template>
    <div class="second-form-page">
        <GlobalHeader />
        <DoAwaiting :for="resourceInitialzingPromise">
            <template #resolved>
                <SecondForm
                    @submit="handleSubmit"
                    @go-to-back="handleGoToBack"
                />
            </template>
            <template #rejected>
                <!-- TODO: リソース初期化時のエラーハンドリング -->
                <FieldErrorText>
                    初期化時にエラーが発生しました。
                </FieldErrorText>
            </template>
        </DoAwaiting>
        <GlobalFooter />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { ROUTE_PATHS } from "../../../../../router/paths";
import { useResourcesStore } from "../../../../../store/resources";
import { useWebFormStore } from "../../../../../store/webform";
import FieldErrorText from "../../../../ui/Atoms/FieldErrorText.vue";
import GlobalFooter from "../../../../ui/Atoms/GlobalFooter.vue";
import GlobalHeader from "../../../../ui/Atoms/GlobalHeader.vue";
import DoAwaiting from "../../../../utils/DoAwaiting.vue";
import SecondForm from "./elements/SecondForm.vue";

export default defineComponent({
    name: "SecondFormPage",
    components: {
        SecondForm,
        DoAwaiting,
        GlobalHeader,
        GlobalFooter,
        FieldErrorText,
    },
    props: {
        storybook: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const resourcesStore = useResourcesStore();

        const _resourceInitialzingPromise = resourcesStore.init();
        const resourceInitialzingPromise = computed(
            () => _resourceInitialzingPromise
        );

        const formStore = useWebFormStore();
        const existSecondFormErrors = computed(
            () => formStore.existSecondFormErrors
        );

        const setSecondFormForceValidation =
            formStore.setSecondFormForceValidation;

        return {
            resourceInitialzingPromise,
            existSecondFormErrors,
            setSecondFormForceValidation,
        };
    },
    methods: {
        handleSubmit() {
            this.setSecondFormForceValidation(true);

            if (this.existSecondFormErrors) {
                alert("入力内容を確認してください。");
                console.debug("入力不備あり");
                return false;
            }
            if (this.$props.storybook) {
                alert("次の画面へ進む");
                return;
            }

            this.$router.push({ path: ROUTE_PATHS.FIRST_CONFIRM });
        },
        handleGoToBack() {
            if (this.$props.storybook) {
                alert("前の画面へ戻る");
                return;
            }

            this.$router.push({ path: ROUTE_PATHS.FIRST_FORM });
        },
    },
});
</script>

<style lang="scss" scoped>
.second-form-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100vh;
}
</style>
