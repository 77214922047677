import "../scss/styles.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCircleNotch,
    faSpinner,
    faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "./App.vue";
import { router } from "./router";

library.add(faSpinner, faWindowRestore, faCircleNotch);

const enableMSW =
    `${import.meta.env.VITE_USE_MSW || ""}`.toLowerCase() === "true";

const prepare = async () => {
    console.debug("import.meta.env", { ...import.meta.env });
    if (import.meta.env.DEV && enableMSW) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { worker } = await import("./mocks/browser");
        console.debug({ worker });
        worker.start();
    }

    return Promise.resolve();
};

const main = () => {
    // application
    const app = createApp(App);

    if (
        import.meta.env.VITE_APP_ENV === "production" ||
        import.meta.env.VITE_APP_ENV === "staging" ||
        import.meta.env.VITE_APP_ENV === "heroku"
    ) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation:
                        Sentry.vueRouterInstrumentation(router),
                    // tracePropagationTargets: ['localhost']
                }),
            ],
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        });
        Sentry.setTag("release", import.meta.env.VITE_APP_RELEASE);
    }

    app.component("font-awesome-icons", FontAwesomeIcon);
    app.use(createPinia());
    app.use(router);
    app.mount("#app");
};

prepare().then(() => {
    main();
});
