import merge from "lodash.merge";
import { defineStore } from "pinia";
import { ref } from "vue";

type State = {
    visited: {
        firstForm: boolean;
        secondForm: boolean;
        firstConfirm: boolean;
        secondConfirm: boolean;
        finished: boolean;
    };
};

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

const genInitState = (): State => ({
    visited: {
        firstForm: false,
        secondForm: false,
        firstConfirm: false,
        secondConfirm: false,
        finished: false,
    },
});

export const usePagination = defineStore("usePagination", () => {
    const state = ref<State>(genInitState());
    const $reset = () => (state.value = genInitState());

    const mergeState = (payload: RecursivePartial<State>) =>
        merge({}, state.value, payload);

    const visitFirstFormPage = () =>
        (state.value = mergeState({
            visited: {
                firstForm: true,
            },
        }));

    const visitSecondFormPage = () =>
        (state.value = mergeState({ visited: { secondForm: true } }));

    const visitFirstConfirmPage = () =>
        (state.value = mergeState({ visited: { firstConfirm: true } }));

    const visitSecondConfirmPage = () =>
        (state.value = mergeState({ visited: { secondConfirm: true } }));

    const visitFinishedPage = () =>
        (state.value = mergeState({ visited: { finished: true } }));

    const unVisitedFinishedPage = () =>
        (state.value = mergeState({ visited: { finished: false } }));

    return {
        state,
        $reset,
        visitFirstFormPage,
        visitSecondFormPage,
        visitFirstConfirmPage,
        visitSecondConfirmPage,
        visitFinishedPage,
        unVisitedFinishedPage,
    };
});
