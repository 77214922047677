<template>
    <input
        :id="id"
        ref="input"
        :value="modelValue"
        :name="name"
        :type="type"
        class="input-field"
        :class="{ error, valid, block }"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :required="required"
        :size="size"
        :step="step"
        :pattern="pattern"
        :max="max"
        :min="min"
        :maxlength="maxlength"
        :minlength="minlength"
        @change.prevent="
            $emit(
                'update:modelValue',
                ($event.currentTarget as HTMLInputElement).value
            )
        "
        @blur="$emit('blur', $event)"
        @keyup="
            $emit('keyup', ($event.currentTarget as HTMLInputElement).value)
        "
    />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { INPUT_FIELD_TYPES } from "../../../interfaces/components";
import { AutocompleteValue } from "../../../types/components";

type AllowedInputType = (typeof INPUT_FIELD_TYPES)[number];

export default defineComponent({
    name: "InputField",
    props: {
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        valid: {
            type: Boolean,
            required: false,
            default: false,
        },
        block: { type: Boolean, default: false },

        autocomplete: {
            type: String as PropType<AutocompleteValue>,
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: undefined,
        },
        required: {
            type: Boolean,
            required: false,
        },
        size: {
            type: Number,
            required: false,
            default: undefined,
        },
        step: {
            type: [Number, String] as PropType<number | "any">,
            required: false,
            default: undefined,
        },
        type: {
            type: String as PropType<AllowedInputType>,
            required: false,
            default: "text",
        },
        id: {
            type: String,
            required: false,
            default: undefined,
        },
        name: {
            type: String,
            required: false,
            default: undefined,
        },
        pattern: {
            type: String,
            required: false,
            default: undefined,
        },

        max: {
            type: Number,
            required: false,
            default: undefined,
        },
        min: {
            type: Number,
            required: false,
            default: undefined,
        },
        maxlength: {
            type: Number,
            required: false,
            default: undefined,
        },
        minlength: {
            type: Number,
            required: false,
            default: undefined,
        },

        modelValue: {
            type: [String, Number],
            required: false,
            default: "",
        },
    },
    emits: ["update:modelValue", "blur", "keyup"],
    // watch: {
    //     modelValue(newVal, oldVal) {
    //         console.debug("watch:modelValue", { newVal, oldVal });
    //     },
    // },
    methods: {
        getInputValue() {
            return (this.$refs.input as HTMLInputElement).value;
        },
    },
});
</script>

<style lang="scss" scoped>
$valid-color: #e9f8fb;

.input-field {
    display: inline-block;
    width: auto;
    padding: 0.4rem;
    border: 1px solid #aaa;
    border-radius: 3px;
    box-sizing: border-box;

    outline: none;
    vertical-align: top;

    font-size: 0.85rem;
    text-indent: 3px;

    overflow: hidden;
    background-color: #fff;

    &.block {
        display: block;
        width: 100%;
    }

    &.valid {
        background-color: $valid-color;
    }

    &.error {
        background-color: #f66;
    }

    &:read-only:not(:disabled) {
        background-color: #eee;
        color: #757575;
    }

    &:focus-visible:not(:read-only):not(:disabled) {
        background-color: $valid-color;
    }

    &:disabled {
        background-color: #d8d8d8;
        color: rgb(52, 52, 52);
        cursor: not-allowed;
    }
}
</style>
