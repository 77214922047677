import { companyTypes } from "./company-types";

type CompanyNames = {
    prefix?: string;
    name: string;
    suffix?: string;
};

export const extractCompanyNames = (fullCompanyName: string): CompanyNames => {
    // console.group("extractCompanyNames");
    // console.debug("# start", { fullCompanyName });
    const res: CompanyNames = {
        prefix: undefined,
        name: fullCompanyName,
        suffix: undefined,
    };

    const hasPrefix = companyTypes.some((t) =>
        new RegExp(`^${t}`, "g").test(fullCompanyName)
    );
    const hasSuffix = companyTypes.some((t) =>
        new RegExp(`${t}$`, "g").test(fullCompanyName)
    );

    if (!hasPrefix && !hasSuffix) {
        // console.debug("return #1", { ...res });
        // console.groupEnd();
        return res;
    }

    let companyName = fullCompanyName;

    const prefix = companyTypes.find((t) =>
        new RegExp(`^${t}`, "g").test(companyName)
    );
    if (prefix) {
        res.prefix = prefix;
        companyName = companyName.substring(res.prefix.length);
    }

    // console.debug("# after prefix", { prefix: res.prefix, companyName });

    const suffix = companyTypes.find(
        (t) =>
            new RegExp(`${t}$`, "g").test(companyName) &&
            companyName.lastIndexOf(t) !== 0 &&
            companyName.length !== t.length
    );
    if (suffix) {
        res.suffix = suffix;
        companyName = companyName.substring(
            0,
            companyName.lastIndexOf(res.suffix)
        );
    }

    // console.debug("# after suffix", { prefix, companyName, suffix });

    res.name = companyName;

    // console.debug("# end", { ...res });

    // console.debug("return #2", { ...res });
    // console.groupEnd();
    return res;
};
