<template>
    <component :is="tag" class="section-header">
        <span><slot></slot></span>
    </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "SectionHeader",
    props: {
        level: {
            type: Number,
            default: 2,
            validator: (value: number) => {
                return 1 <= value && value <= 6;
            },
        },
    },
    computed: {
        tag: function () {
            return `h${this.$props.level}`;
        },
    },
});
</script>

<style lang="scss" scoped>
.section-header {
    display: flex;
    align-items: flex-start;

    &::before {
        content: url(/images/icon_square.gif);
        width: 28px;
        height: 28px;
    }

    & > span {
        display: inline-block;
        padding-left: 7px;
        font-size: 1.5rem;
        line-height: 1.675rem;
        margin: 0.1125rem 0;
    }
}
</style>
