<template>
    <div class="form-field-group">
        <div class="field-title-container">
            <span class="title"><slot name="title"></slot></span>
            <template v-if="required">
                <span class="required-label">必須</span>
            </template>
        </div>
        <div class="field-inputs-container">
            <div class="content-container">
                <div v-if="!!$slots['content']" class="content">
                    <slot name="content"></slot>
                </div>
                <div v-if="!!$slots['sub-content']" class="sub-content">
                    <slot name="sub-content"></slot>
                </div>
            </div>
            <div class="status-icon">
                <i :class="{ error }"></i>
                <i :class="{ valid }"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "FormFieldGroup",
    props: {
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        valid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
.form-field-group {
    width: 100%;
    display: grid;
    grid-template-columns: 252px 1fr;

    @media screen and (max-width: 768px) {
        width: auto;
        grid-template-columns: 1fr;
    }
}

.field-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    box-sizing: border-box;
    // border: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    padding: 15px 15px;
    min-height: 68px;

    background-color: #e9f8fb;

    @media screen and (max-width: 768px) {
        border-right: none;
    }

    .title {
        flex: 1 1;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;

        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4em;
        word-break: normal;
        // word-break: normal;
    }
    .required-label {
        flex: 0 0;
        display: block;
        padding: 0.135rem 0.625rem;
        margin-left: 15px;

        background: #f66;
        color: #fff;
        font-weight: bold;
        font-size: 0.875rem;
        word-break: keep-all;
        line-height: 0.875rem;
    }
}
.field-inputs-container {
    background-color: #f5f5f5;
    box-sizing: border-box;
    //border: 2px solid #fff;
    // border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex: 1 0;

    .content-container {
        flex: 1 1;
        box-sizing: border-box;
        padding: 1.125rem;
        width: calc(100% - 38px);
        line-height: 1rem;
        .content {
            box-sizing: border-box;
            line-height: 1rem;
        }
        .sub-content {
            margin-top: 1rem;
        }

        @media screen and (max-width: 768px) {
            width: auto;
        }
    }

    .status-icon {
        min-width: 38px;
        box-sizing: border-box;
        padding-right: 1.125rem;

        display: flex;
        flex: 0 0;
        align-items: center;
        justify-content: flex-start;
        // & > img {
        //     display: none;
        //     &.error,
        //     &.valid {
        //         display: inline-block;
        //     }
        // }
        & > i {
            display: none;
            &.error,
            &.valid {
                display: block;
                width: 22px;
                height: 22px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.error {
                background-image: url("/images/error.png");
            }
            &.valid {
                background-image: url("/images/checked.png");
            }
        }
    }
}
</style>
