<template>
    <div class="first-form-page">
        <GlobalHeader />
        <CenteredLayout>
            <form
                class="first-form"
                data-gtm-form-interact-id="0"
                @submit.prevent="handleSubmit"
            >
                <p>
                    ※ブラウザの「戻る」「進む」ボタンはご使用にならないようお願いいたします。
                </p>
                <PrivacyInfoSection />

                <p class="form-description">
                    以下のフォームに必要な情報をご入力の上「次へ」を押してください。
                </p>

                <NxwSalesPersonSection />
                <ApplicationInfoSection />
                <CustomerInfoSection />
                <LoginInfoSection />
                <ApproverInfoSection />

                <div class="submit-button-container">
                    <NextButton type="submit" />
                </div>
            </form>
        </CenteredLayout>
        <GlobalFooter />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { ROUTE_PATHS } from "../../../../../router/paths";
import { useResourcesStore } from "../../../../../store/resources";
import { useWebFormStore } from "../../../../../store/webform";
import GlobalFooter from "../../../../ui/Atoms/GlobalFooter.vue";
import GlobalHeader from "../../../../ui/Atoms/GlobalHeader.vue";
import NextButton from "../../../../ui/Atoms/NextButton.vue";
import CenteredLayout from "../../../../ui/Templates/CenteredLayout.vue";
import ApplicationInfoSection from "./elements/ApplicationInfoSection.vue";
import ApproverInfoSection from "./elements/ApproverInfoSection.vue";
import CustomerInfoSection from "./elements/CustomerInfoSection.vue";
import LoginInfoSection from "./elements/LoginInfoSection.vue";
import NxwSalesPersonSection from "./elements/NxwSalesPersonSection.vue";
import PrivacyInfoSection from "./elements/PrivacyInfoSection.vue";

export default defineComponent({
    name: "FirstFormPage",
    components: {
        GlobalHeader,
        CenteredLayout,
        PrivacyInfoSection,
        NxwSalesPersonSection,
        ApplicationInfoSection,
        CustomerInfoSection,
        ApproverInfoSection,
        NextButton,
        GlobalFooter,
        LoginInfoSection,
    },
    props: {
        storybook: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const firstFormStore = useWebFormStore();
        const existFirstFormErrors = computed(
            () => firstFormStore.existFirstFormErrors
        );
        const setFirstFormForceValidation =
            firstFormStore.setFirstFormForceValidation;

        const resourcesStore = useResourcesStore();
        const initing = resourcesStore.init();
        const initializingPromise = computed(() => initing);
        const prodInitialized = computed(
            () => resourcesStore.state.product.initialized
        );
        const prodErrors = computed(() => resourcesStore.state.product.errors);

        return {
            initializingPromise,
            existFirstFormErrors,
            prodInitialized,
            prodErrors,
            setFirstFormForceValidation,
        };
    },
    computed: {
        errorsDuringInitializing() {
            return this.prodInitialized && this.prodErrors.length > 0;
        },
        initializedOnSuccessfuly() {
            return this.prodInitialized && this.prodErrors.length === 0;
        },
    },
    methods: {
        handleSubmit(e: Event) {
            this.setFirstFormForceValidation(true);

            if (this.existFirstFormErrors) {
                alert("入力内容を確認してください。");
                console.debug("入力内容を確認してください。");
                return false;
            }

            if (this.$props.storybook) {
                alert("次の画面へ進む");
                return;
            }

            this.$router.push({ path: ROUTE_PATHS.SECOND_FORM });
        },
    },
});
</script>

<style lang="scss" scoped>
.first-form-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100vh;

    .first-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
        .form-description {
            font-size: 0.875em;
            margin: 0 0 2.25rem;
        }

        @media screen and (max-width: 767px) {
            width: auto;
        }
    }

    .submit-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
</style>
