<template>
    <FormSection
        title="承認者情報(申込者の方以外に別途承認者が必要な場合に入力ください)"
    >
        <!-- 部署・氏名 -->
        <FormFieldGroup
            :valid="!!approverInfo.deptOrName && !errors.deptOrName"
            :error="!!errors.deptOrName"
        >
            <template #title>{{ labels.approverInfo.deptOrName }}</template>
            <template #content>
                <InputField
                    :model-value="approverInfo.deptOrName"
                    block
                    name="approver_info_dept_or_name"
                    :valid="!!approverInfo.deptOrName && !errors.deptOrName"
                    :maxlength="50"
                    :error="!!errors.deptOrName"
                    placeholder="例）○○部 △△太郎"
                    @update:model-value="handleUpdate('deptOrName', $event)"
                    @keyup="handleUpdate('deptOrName', $event)"
                />
            </template>
            <template v-if="!!errors.deptOrName" #sub-content>
                <FieldErrorText>{{
                    errors.deptOrName
                }}</FieldErrorText></template
            >
        </FormFieldGroup>

        <!-- メールアドレス -->
        <FormFieldGroup
            :valid="!!approverInfo.email && !errors.email"
            :error="!!errors.email"
        >
            <template #title>{{ labels.approverInfo.email }}</template>
            <template #content>
                <InputField
                    name="approver_info_email"
                    :model-value="approverInfo.email"
                    type="email"
                    :valid="!!approverInfo.email && !errors.email"
                    :error="!!errors.email"
                    placeholder="例）xxxxx@example.com"
                    block
                    :maxlength="80"
                    :pattern="emailPatternStr"
                    @update:model-value="handleUpdate('email', $event)"
                    @keyup="handleUpdate('email', $event)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角英数記号のみ</HelpText>
                <FieldErrorText v-if="!!errors.email">{{
                    errors.email
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>
    </FormSection>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { EMAIL_PATTERN_REGEXP } from "../../../../../../libs/patterns";
import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import HelpText from "../../../../../ui/Atoms/HelpText.vue";
import InputField from "../../../../../ui/Atoms/InputField.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";

export type ApproverInfoState = WebFormState["approverInfo"];

export default defineComponent({
    name: "ApproverInfoSection",
    components: {
        FieldErrorText,
        FormFieldGroup,
        HelpText,
        InputField,
        FormSection,
    },
    setup() {
        const store = useWebFormStore();

        const approverInfo = computed(() => store.state.approverInfo);
        const setApproverInfoState = store.setApproverInfoState;
        const approverInfoErrors = store.approverInfoErrors;
        const labels = store.labels;

        return {
            approverInfo,
            setApproverInfoState,
            approverInfoErrors,
            labels,
        };
    },
    computed: {
        errors() {
            return this.approverInfoErrors();
        },
        emailPattern() {
            return EMAIL_PATTERN_REGEXP;
        },
        emailPatternStr() {
            return this.emailPattern.toString().replace(/\//gm, "");
        },
    },
    methods: {
        async handleUpdate(
            key: keyof ApproverInfoState,
            value: ApproverInfoState[keyof ApproverInfoState]
        ) {
            this.setApproverInfoState({
                [key]: typeof value === "string" ? value.trim() : value,
            });
        },
    },
});
</script>

<style lang="scss" scoped></style>
