<template>
    <FormSection title="申込情報">
        <!-- <FormFieldGroup required>
            <template #title>申込種別</template>
            <template #content>
                <RadioButtons
                    :model-value="applicationInfo.registrationType"
                    name="registrationType"
                    :items="[]"
                    @update:model-value="handleUpdate('registrationType', $event)"
                />
            </template>
        </FormFieldGroup> -->

        <!-- お申し込みサービス -->
        <FormFieldGroup
            required
            :valid="!!applicationInfo.product && !errors.product"
            :error="!!errors.product"
        >
            <template #title>
                {{ labels.applicationInfo.product }}
            </template>
            <template #content>
                <DoAwaiting :for="productsPromise">
                    <template #resolved>
                        <SelectField
                            name="application_info_product"
                            :model-value="applicationInfo.product"
                            :valid="!!applicationInfo.product"
                            :error="!!errors.product"
                            required
                            :options="prodListOptions"
                            block
                            @update:model-value="
                                handleUpdate('product', $event)
                            "
                        />
                    </template>
                    <template #rejected>
                        <template v-if="productErrors.length > 0">
                            <FieldErrorText
                                v-for="(msg, i) in productErrors"
                                :key="i"
                            >
                                {{ msg }}</FieldErrorText
                            >
                        </template>
                        <template v-else>
                            <FieldErrorText>
                                エラーが発生しました。
                            </FieldErrorText>
                        </template>
                    </template>
                    <template #something-went-wrong>
                        <FieldErrorText>
                            予期しない問題が発生しました。
                        </FieldErrorText>
                    </template>
                </DoAwaiting>
            </template>
            <template v-if="!!errors.product" #sub-content>
                <FieldErrorText>{{ errors.product }}</FieldErrorText>
            </template>
        </FormFieldGroup>
    </FormSection>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useResourcesStore } from "../../../../../../store/resources";
import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import SelectField, { Option } from "../../../../../ui/Atoms/SelectField.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";
import DoAwaiting from "../../../../../utils/DoAwaiting.vue";

export type ApplicationInfoState = WebFormState["applicationInfo"];

export default defineComponent({
    name: "ApplicationInfoSection",
    components: {
        SelectField,
        FieldErrorText,
        FormFieldGroup,
        FormSection,
        DoAwaiting,
    },
    setup() {
        const formStore = useWebFormStore();

        const applicationInfo = computed(() => formStore.state.applicationInfo);
        const setApplicationInfoState = formStore.setApplicationInfoState;
        const applicationInfoErrors = formStore.applicationInfoErrors;

        const resourcesStore = useResourcesStore();

        const productItems = computed(() => resourcesStore.productItems);

        const labels = formStore.labels;

        const productsPromise = computed(
            () => resourcesStore.state.product.promise
        );

        const productErrors = computed(
            () => resourcesStore.state.product.errors
        );

        return {
            applicationInfo,
            setApplicationInfoState,
            applicationInfoErrors,
            productItems,
            labels,
            productsPromise,
            productErrors,
        };
    },
    computed: {
        errors() {
            return this.applicationInfoErrors();
        },
        prodListOptions(): Option[] {
            const prods = this.productItems;
            return [
                { key: -1, value: "", label: "---" },
                ...prods.map((p, i) => ({
                    key: i,
                    value: p.id,
                    label: p.name,
                })),
            ];
        },
    },
    methods: {
        handleUpdate(
            key: keyof ApplicationInfoState,
            value: ApplicationInfoState[keyof ApplicationInfoState]
        ) {
            this.setApplicationInfoState({ [key]: value });
        },
    },
});
</script>

<style lang="scss" scoped></style>
