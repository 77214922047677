<template>
    <FormSection class="private-info-section">
        <div class="privacy-mark-description">
            <p>
                個人情報の取扱いについてをよくご確認いただき、同意の上、項目のご入力をお願いいたします。
            </p>
            <a href="http://privacymark.jp/" target="_blank">
                <img
                    src="//www.nexway.co.jp/common2014/common/privacymark_75.gif"
                    alt="大切にします。プライバシー 17000014"
                    width="75"
                    height="75"
                />
            </a>
        </div>
        <h2 class="privacy-info-description-header">
            個人情報の取扱いについて
        </h2>
        <div class="article">
            <p>
                お預かりした個人情報（氏名・住所・電話番号・メールアドレス等）は、以下に記載した利用目的においてのみ当社が利用させていただきます。<br />
                ＜利用目的＞<br />
                ・お問い合わせに対するご回答<br />
                ・当社のサービスに関する営業行為<br />
                ・メールマガジンの配信<br />
                ・資料請求やアンケートに対する資料やプレゼントの発送先<br />
                ・弊社サービス改善又は新たなサービス開発に関するご意見をお伺いする先として
            </p>
            <p>
                原則として、ご本人の承諾なしに、上記利用目的以外に個人情報を利用または第三者に提供する事はいたしません。ただし、ネクスウェイは個人情報を取扱う業務の一部または全部を当社の監督の下、外部委託することがあります。<br />
                ※弊社サービスやセミナーの案内が不要な場合は申請いただければ、弊社からの情報提供を停止させていただきます。
            </p>
            <p>
                ＜個人情報管理責任者：株式会社ネクスウェイ
                販売促進担当責任者＞<br />
                個人情報の取扱いに関するお問合せおよび個人情報の開示、訂正、追加、削除、利用の停止、提供の停止、利用目的の開示等は下記までご連絡ください。<br />
                株式会社ネクスウェイ 個人情報についてのお問合せ<br />
                TEL：0120-341-890／E-mail：
                <a href="mailto:clp@nexway.co.jp">clp@nexway.co.jp</a><br />
                ネクスウェイのプライバシーポリシー：
                <a href="https://www.nexway.co.jp/privacy.html" target="_blank">
                    https://www.nexway.co.jp/privacy.html
                </a>
                <br />
                サイトご利用にあたって：
                <a
                    href="https://www.nexway.co.jp/site_policy/index.html"
                    target="_blank"
                >
                    https://www.nexway.co.jp/site_policy/index.html
                </a>
            </p>
        </div>

        <!-- 個人情報の取り扱い -->
        <FormFieldGroup
            required
            :valid="privacyInfo.agree && !errors.agree"
            :error="!!errors.agree"
        >
            <template #title>{{ labels.privacyInfo.agree }}</template>
            <template #content>
                <Checkbox
                    required
                    name="privacy_info_agree"
                    :model-value="privacyInfo.agree"
                    :label="labels.privacyInfo.agreeCheckbox"
                    data-gtm-form-interact-field-id="0"
                    @update:model-value="handleUpdate('agree', $event)"
                />
            </template>
            <template v-if="!!errors.agree" #sub-content>
                <FieldErrorText>{{ errors.agree }}</FieldErrorText>
            </template>
        </FormFieldGroup>
    </FormSection>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import Checkbox from "../../../../../ui/Atoms/Checkbox.vue";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";

export type PrivacyInfoState = WebFormState["privacyInfo"];

export default defineComponent({
    name: "PrivacyInfoSection",
    components: {
        Checkbox,
        FieldErrorText,
        FormSection,
        FormFieldGroup,
    },
    setup() {
        const store = useWebFormStore();

        const privacyInfo = computed(() => store.state.privacyInfo);
        const setPrivacyInfoState = store.setPrivacyInfoState;
        const privacyInfoErrors = store.privacyInfoErrors;
        const labels = store.labels;

        return { privacyInfo, setPrivacyInfoState, privacyInfoErrors, labels };
    },
    computed: {
        errors() {
            return this.privacyInfoErrors();
        },
    },
    methods: {
        handleUpdate(
            key: keyof PrivacyInfoState,
            value: PrivacyInfoState[keyof PrivacyInfoState]
        ) {
            this.setPrivacyInfoState({ [key]: value });
        },
    },
});
</script>

<style lang="scss" scoped>
.private-info-section {
    .privacy-info-description-header {
        margin-bottom: 0.625rem;
        line-height: 1.4rem;
        font-weight: bold;
        font-size: 1.125rem;
    }

    .privacy-mark-description {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;

        & > p {
            flex: 1 0;
        }
        & > a {
            flex: 0 1;
            display: inline-block;
        }
    }
    .article {
        height: 100px;
        overflow: auto;
        box-sizing: content-box;

        padding: 0.625rem;
        margin-bottom: 1.25rem;
        border: 1px solid #ddd;

        p {
            line-height: 1.5em;
            //font-size: 0.875em;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>
