<template>
    <div class="centered-layout">
        <div><slot></slot></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "CenteredLayout",
});
</script>

<style lang="scss" scoped>
.centered-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > div {
        width: 800px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
            box-sizing: border-box;
            width: 100%;
            padding: 0 1rem;
        }
    }
}
</style>
